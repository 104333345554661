.object-form-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 800px;
    flex-wrap: wrap;
    .object-form-col{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}