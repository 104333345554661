.warning-dialog-window{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    p{
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
        color: rgb(89, 89, 89);
        margin-bottom: 12px;
        &.smart{
            color: rgb(84, 84, 84);
            font-size: 18px;
            font-weight: 300;
            line-height: 21px;
        }
    }
    .btn-line{
        margin: 20px 0px 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    button{
        
    }
}

.swal2-warning{
    border: 4px solid #facea8;
    // border-color: #;
    color: #f8bb86;
    height: 80px;
    width: 80px;
    position: relative;
    border-radius: 44px;
    margin: 20px 192px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .swal2-icon-text{
        font-size: 60px;
        font-weight: 300;
        line-height: 80px;
    }
}