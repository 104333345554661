.password-strength-block{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p{
        font-size: 11px;
        font-weight: 400;
        line-height: 21px;
        color: rgb(51, 51, 51);
    }
    ul{
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 15px;
        padding: 0;

        
        &.level-0{
            li{
                background: rgb(221, 221, 221);
            }
            
        }
        &.level-1{
            li:nth-child(1){
                background: rgb(255, 0, 0);
            }
            
        }
        
        &.level-2{
            li:nth-child(1){
                background: #ffeb3b;//rgb(255, 153, 0);
            }
            li:nth-child(2){
                background: #ffeb3b;//rgb(255, 153, 0);
            }
            
        }
        &.level-3{
            li:nth-child(1){
                background: rgb(255, 153, 0);
            }
            li:nth-child(2){
                background: rgb(255, 153, 0);
            }
            li:nth-child(3){
                background: rgb(255, 153, 0);
            }
            
        }
        &.level-4{
            li:nth-child(1){
                background: rgb(153, 255, 0);
            }
            li:nth-child(2){
                background: rgb(153, 255, 0);
            }
            li:nth-child(3){
                background: rgb(153, 255, 0);
            }
            li:nth-child(4){
                background: rgb(153, 255, 0);
            }
        }
        &.level-5{
            li{
                background: rgb(0, 255, 0);
            }
            
        }
        li{
            width: 20px;
            height: 5px;
            border-radius: 2px;
            margin-right: 1px;
            background: rgb(221, 221, 221);
        }
    }
}