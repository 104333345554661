.field-wrapper{
    position: relative;
    // z-index: 100;
    box-sizing: border-box;
    &.center-input-text input{
        text-align: center;
    }
    .material-icons.MuiIcon-root{
        position: absolute;
        bottom: 6.125px;
        right: 0;
        font-size: 16px;
        color:rgb(116, 116, 116);
        // z-index: -1;
    }
    &.datepicker{
        // z-index: 200;
        input.form-control{
            padding-right: 30px;
        }
    }
    .autocomplete-progress{
        width: 15px !important;
        height: 15px !important;
        .MuiCircularProgress-svg {
            color: #2196f3 !important;
        }
    }
    .autocomplete-dropdown{
        z-index: 5000;
        width: 100%;
        min-width: 200px;
        max-height: 256px;
        overflow-y: scroll;
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
        background: #fff;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
        p{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 6px;
            box-sizing: border-box;
            margin: 5px 5px 0 5px;
            font-size: 12px;
            font-weight: 300;
            line-height: 48px;
            transition: .3s;
            color:rgba(0, 0, 0, 0.87);
            border-radius: 3px;
            &:last-child{
                margin-bottom: 5px;
            }
            &:hover{
                cursor: pointer;
                background: #2196f3;
                color: #fff;
            }
        }
    }
    label{
        color: rgba(0, 0, 0, 0.54);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
    &>svg{
        position: relative;
        margin: 20px auto 3px;
        transition: .3s;
        &.checked{
            color: #2196f3;
        }
        &.unchecked{
            color: rgb(170, 170, 170);
        }
    }
    .MuiInput-input{
        height: 15px;
        font-size: 14px;
    }
    .MuiInput-underline:before {
        display: none;
    }
    .MuiInput-underline:after {
        display: none;
    }
    .MuiInputBase-root.Mui-disabled{
        
        &:before{
            border-bottom-style: none;
            content: "";
            display: block;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 1px;
            background-image: linear-gradient(to right,rgba(0,0,0,.42) 0,rgba(0,0,0,.42) 33%,transparent 0);
            background-size: 4px 100%, 100% 1px;
            background-repeat: repeat-x;
        }
    }

    .Mui-error{
        .MuiInput-input {
            background-image: linear-gradient(#f44336, #f44336), linear-gradient(#f44336, #f44336);
            &:focus {
                background-image: linear-gradient(#f44336, #f44336), linear-gradient(#f44336, #f44336);
            }
            &::-webkit-input-placeholder { /* Edge */
                color: #f44336;
                // opacity: 1;
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #f44336;
                // opacity: 1;
            }
            
            &::placeholder {
                color: #f44336;
                // opacity: 1;
            }
        }
    }
    .MuiInputBase-multiline {
        padding: 2px 0 0px;
    }
    .MuiInput-input {
        border: 0;
        background-image: linear-gradient(#2196f3, #2196f3),
          linear-gradient(rgb(51, 51, 51), rgb(51, 51, 51));
        background-size: 0 2px, 100% 1px;
        background-repeat: no-repeat;
        background-position: center bottom, center calc(100% - 1px);
        background-color: rgba(0, 0, 0, 0);
        transition: background 0s ease-out;
        float: none;
        box-shadow: none;
        border-radius: 0;
        font-weight: 400;
        width: 100%;
        // height: 36px;
        padding: 6.125px 0;
        font-size: 14px;
        line-height: 1.428571429;
        display: block;
        width: 100%;
        color: rgb(51, 51, 51);
        line-height: 15.75px;
        position: relative;
        &.Mui-disabled{
            background-image: none;
            &:before{
                // content: "";
                // display: block;
                // width: 100%;
                // bottom: 0;
                // left: 0;
                // height: 1px;
                // background-image: linear-gradient(to right,rgba(0,0,0,.42) 0,rgba(0,0,0,.42) 33%,transparent 0);
                // background-size: 4px 100%, 100% 1px;
                // background-repeat: repeat-x;
            }
        }
      }
      .MuiInput-input:focus {
        outline: none;
        background-image: linear-gradient(#2196f3, #2196f3),
          linear-gradient(rgb(51, 51, 51), rgb(51, 51, 51));
        background-size: 100% 2px, 100% 1px;
        box-shadow: none;
        transition-duration: 0.3s;
      }

    .MuiFormHelperText-root.Mui-error {
        color: #f44336;;
        position: absolute;
        bottom: 0;
        transform: translateY(100%) translateY(5px);
        font-size: 10.5px;
    }
    // margin-bottom: 27px;
    // padding-bottom: 27.5px;
    margin-bottom: 17.5px;
    margin-top: 8px;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input.MuiInputBase-inputSelect {
    padding-right: 23px;
}

.MuiInputLabel-root.Mui-focused{
    color: #2196f3 !important;
}

.field-wrapper.center-field-mode {
    padding: 0 10px !important;
    margin-top: 0;
    &.without-label-border{
        label {
            border-bottom: 0px solid #000;
            border-top: 0px solid #000;
        }
    }
    label {
        width: calc(100% + 20px) !important;
        margin-left: -10px;
        align-content: center;
        justify-content: center;
        display: flex;
        font-size: 12px;
        font-style: italic;
        line-height: 24px;
        color: #000;
        padding: 5px;
        position: relative;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        transform: none;
        border-bottom: 1px solid #000;
        border-top: 1px solid #000;
        box-sizing: border-box;
    }
}