.table-header-item{
    position: relative;
    padding-right: 18px;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    color: rgb(60, 72, 88);
    &.asc{
        .sort-arrow-down{
            opacity: 0;
        }
    }
    &.desc{
        .sort-arrow-up{
            opacity: 0;
        }
    }
}

.table-body-item{
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    color: rgb(60, 72, 88);
    line-height: 21px;
    a{
        text-decoration: none;
        color: #2196f3;
    }
    &.bold{
        font-weight:700;
    }
}

.sort-arrow-up{
    transition: .3s;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateY(-3px);
    font-size: 20px !important;
}
.sort-arrow-down{
    transition: .3s;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateY(3px);
    font-size: 20px !important;
}
.MuiTableCell-body{
    button{
        background: none;
        height: 40px;
        width: 40px;
        padding: 0;
        border: none;
        margin: 0 10px;
        outline: none;
        cursor: pointer;
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
    }
}
.MuiTableCell-root {
    padding: 5px 10px !important;
}

.MuiTableRow-root{
    min-height: 62px;
    height: 62px;
    &.MuiTableRow-head{
        min-height: 44px;
        height: 44px;
    }
}


.MuiTableCell-body .material-icons {
    color: #fff;
    background-color: #999;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 3px;
    font-size: 20px;
    
}

.MuiTablePagination-root {
    padding-right: 50px !important;
}