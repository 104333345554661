.check-me-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    
    .Mui-checked {
        color: rgb(33, 150, 243) !important;
    }
}

.reset-password-link{
    color: rgb(33, 150, 243);
    font-size: 14px;
    text-decoration: none;
    font-weight: 300;
    line-height: 21px;
}




.checked-me-checkbox {
    margin-bottom: 17.5px !important;
    margin-top: 8px !important;
    padding-left: 11px;
    .MuiFormControlLabel-label{
        color: rgb(170, 170, 170);
        font-size: 14px;
        line-height: 22px;
        // margin-left: -6px;
    }
   .MuiCheckbox-root{
        // color: rgb(33, 150, 243);
        padding: 0;
        margin-right: 8px;
        &.MuiCheckbox-colorPrimary.Mui-checked {
            color: rgb(33, 150, 243);
        }
   }
}

.half-padding{
    width: calc(50% - 30px);
    margin: 13px 0 17.5px !important;
    padding: 0 15px;
}

// .MuiCheckbox-colorPrimary.Mui-checked {
//     color: rgb(33, 150, 243) !important;
//     padding: 0;
// }