@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
.spacer-1 {
  width: 8.33333%; }

.spacer-2 {
  width: 16.66667%; }

.spacer-3 {
  width: 25%; }

.spacer-4 {
  width: 33.33333%; }

.spacer-5 {
  width: 41.66667%; }

.spacer-6 {
  width: 50%; }

.spacer-7 {
  width: 58.33333%; }

.spacer-8 {
  width: 66.66667%; }

.spacer-9 {
  width: 75%; }

.spacer-10 {
  width: 83.33333%; }

.spacer-11 {
  width: 91.66667%; }

.spacer-12 {
  width: 100%; }

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  background: #eee;
  box-sizing: border-box; }

#root {
  height: 100%;
  width: 100%; }

p,
span,
input,
textarea,
a,
h1,
h2,
h3,
h4,
h5,
h6,
div {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

.spinner {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 50px 0; }

.MuiCircularProgress-root {
  height: 50px !important;
  width: 50px !important; }

.MuiCircularProgress-svg {
  color: #4285f4;
  -webkit-animation: colorSpinner infinite 4s linear;
          animation: colorSpinner infinite 4s linear; }
  .MuiCircularProgress-svg circle {
    stroke-width: 2; }

@-webkit-keyframes colorSpinner {
  0% {
    color: #4285f4; }
  25% {
    color: #db4437; }
  50% {
    color: #f4b400; }
  75% {
    color: #0f9d58; }
  100% {
    color: #4285f4; } }

@keyframes colorSpinner {
  0% {
    color: #4285f4; }
  25% {
    color: #db4437; }
  50% {
    color: #f4b400; }
  75% {
    color: #0f9d58; }
  100% {
    color: #4285f4; } }

.MuiDialog-paper {
  overflow-y: unset !important; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.MuiDialog-paperWidthSm {
  max-width: 800px !important; }

.screen-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw; }

.screen-top {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100%; }

.info-block {
  padding: 20px 15px;
  border-radius: 3px;
  background: #2196f3;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 150, 243, 0.4);
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px; }

.add-btn-block {
  border-radius: 28px;
  height: 56px;
  width: 56px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9999;
  overflow: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .add-btn-block .btn-block-item {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #999999;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 28px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 14px;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .add-btn-block .btn-block-item:nth-child(2) {
      top: 78px; }
    .add-btn-block .btn-block-item:nth-child(3) {
      top: 128px; }
  .add-btn-block:hover {
    height: 220px; }
  .add-btn-block:hover + .add-btn-bg {
    height: 100%;
    width: 100%;
    z-index: 999;
    opacity: 1; }

.add-btn-bg {
  height: 100%;
  width: 100%;
  z-index: -100;
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.blue-btn {
  padding: 12px 30px !important;
  background-color: #2196f3 !important; }
  .blue-btn.fullwidth {
    width: 100%; }
  .blue-btn span {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px; }

ul.MuiList-root.MuiMenu-list.MuiList-padding {
  border: 0;
  margin: 0;
  display: block;
  padding: 5px 0;
  font-size: 14px;
  min-width: 100%;
  box-shadow: none;
  box-sizing: border-box;
  list-style: none;
  text-align: left;
  border-radius: 4px;
  background-clip: padding-box;
  background-color: #fff; }

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  clear: both;
  color: #333;
  margin: 0 5px;
  display: block;
  padding: 0px 6px;
  -webkit-transition: all 150ms linear;
  transition: all 150ms linear;
  white-space: nowrap;
  border-radius: 2px;
  padding-right: 30px;
  max-height: 36px;
  height: 36px;
  min-height: 36px;
  font-size: 12px;
  font-weight: 300;
  line-height: 36px; }
  li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button:hover {
    background: #2196f3;
    color: #fff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 150, 243, 0.4); }

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected {
  background: #2196f3;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 150, 243, 0.4);
  color: #fff; }

.right-align-margin-reset-password-btn {
  align-self: flex-end !important;
  justify-self: flex-end !important;
  margin-left: 100% !important;
  position: relative;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  margin-top: 15px !important; }

/*!
* https://github.com/YouCanBookMe/react-datetime
*/
.rdt {
  position: relative; }
  .rdt .rdtPicker {
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0; }
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0; }
  .rdt input.form-control {
    margin-top: 16px;
    border: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2196f3), to(#2196f3)), -webkit-gradient(linear, left top, left bottom, from(#333333), to(#333333));
    background-image: linear-gradient(#2196f3, #2196f3), linear-gradient(#333333, #333333);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: background 0s ease-out;
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    padding: 6.125px 0;
    font-size: 14px;
    display: block;
    width: calc(100% - 30px);
    color: #333333;
    line-height: 15.75px;
    position: relative;
    padding-right: 30px; }
  .rdt input.form-control:focus {
    outline: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2196f3), to(#2196f3)), -webkit-gradient(linear, left top, left bottom, from(#333333), to(#333333));
    background-image: linear-gradient(#2196f3, #2196f3), linear-gradient(#333333, #333333);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s; }

.rdtPicker {
  position: absolute;
  padding: 4px;
  margin-top: 1px;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px;
  left: 50%;
  -webkit-transform: translate(-50%, 10px);
          transform: translate(-50%, 10px);
  z-index: 3000;
  display: block;
  top: 40px; }
  .rdtPicker .dow,
  .rdtPicker th.rdtSwitch,
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev,
  .rdtPicker .rdtTimeToggle {
    color: #000; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #fff !important;
  border-collapse: collapse; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px; }

.rdtPicker td {
  cursor: pointer; }

.rdtDay {
  height: 40px;
  line-height: 33px;
  width: 40px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 300; }
  .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
    background-color: #337ab7 !important;
    color: #fff;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(51, 122, 183, 0.4); }

.rdtDays {
  width: 290px; }
  .rdtDays tr .dow {
    border-bottom: 1px solid #e3e3e3;
    text-align: center;
    font-size: 12px;
    font-weight: inherit;
    padding-bottom: 11px;
    padding-top: 10px;
    color: rgba(0, 0, 0, 0.4);
    text-transform: lowercase; }
  .rdtDays tr .rdtOld,
  .rdtDays tr .rdtNew {
    color: #bdbdbd; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px;
  color: #000;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 40px; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }
  .rdtCounters > div {
    float: left;
    width: 40px;
    font-weight: inherit;
    margin: 3px;
    border-radius: 50%; }
  .rdtCounters .rdtCounterSeparator {
    width: 0;
    border: 1px solid transparent; }

.rdtCounter {
  height: 100px;
  width: 40px; }
  .rdtCounter .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent;
    font-size: inherit;
    line-height: 25px; }

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px; }

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #eeeeee;
  -webkit-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px; }

.rdtMonths,
.rdtYears {
  padding-bottom: 10px; }
  .rdtMonths .rdtMonth,
  .rdtMonths .rdtYear,
  .rdtYears .rdtMonth,
  .rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center; }
    .rdtMonths .rdtMonth.rdtActive,
    .rdtMonths .rdtYear.rdtActive,
    .rdtYears .rdtMonth.rdtActive,
    .rdtYears .rdtYear.rdtActive {
      background-color: #337ab7 !important;
      color: #fff; }

.MuiButton-contained {
  padding: 12px 30px !important; }
  .MuiButton-contained.small {
    padding: 6.5px 20px !important; }
    .MuiButton-contained.small span {
      font-size: 11px !important;
      line-height: 16.5px !important;
      font-weight: 400 !important; }
  .MuiButton-contained span {
    font-size: 12px !important;
    line-height: 17px !important;
    font-weight: 400 !important; }

.MuiButton-contained.primary-btn-color {
  background-color: #2196f3;
  color: #fff;
  border-color: #2196f3;
  box-shadow: 0 2px 2px 0 rgba(33, 150, 243, 0.14), 0 3px 1px -2px rgba(33, 150, 243, 0.2), 0 1px 5px 0 rgba(33, 150, 243, 0.12); }
  .MuiButton-contained.primary-btn-color:hover {
    box-shadow: 0 14px 26px -12px rgba(33, 150, 243, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(33, 150, 243, 0.2);
    color: #fff;
    background-color: #128ff2;
    border-color: #0b76cc; }

.MuiButton-contained.grey-btn-color {
  background-color: #999;
  color: #fff;
  border-color: #999;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12); }
  .MuiButton-contained.grey-btn-color:hover {
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
    color: #fff;
    background-color: #919191;
    border-color: #7a7a7a; }

.MuiButton-contained.red-btn-color {
  background-color: #f44336;
  color: #fff;
  border-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12); }
  .MuiButton-contained.red-btn-color:hover {
    box-shadow: 0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
    color: #fff;
    background-color: #f33527;
    border-color: #e11b0c; }

.MuiButton-contained.green-btn-color {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12); }
  .MuiButton-contained.green-btn-color:hover {
    color: #fff;
    background-color: #47a44b;
    border-color: #39843c;
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12); }

.field-wrapper {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 17.5px;
  margin-top: 8px; }
  .field-wrapper.center-input-text input {
    text-align: center; }
  .field-wrapper .material-icons.MuiIcon-root {
    position: absolute;
    bottom: 6.125px;
    right: 0;
    font-size: 16px;
    color: #747474; }
  .field-wrapper.datepicker input.form-control {
    padding-right: 30px; }
  .field-wrapper .autocomplete-progress {
    width: 15px !important;
    height: 15px !important; }
    .field-wrapper .autocomplete-progress .MuiCircularProgress-svg {
      color: #2196f3 !important; }
  .field-wrapper .autocomplete-dropdown {
    z-index: 5000;
    width: 100%;
    min-width: 200px;
    max-height: 256px;
    overflow-y: scroll;
    position: absolute;
    bottom: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    background: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
    .field-wrapper .autocomplete-dropdown p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 6px;
      box-sizing: border-box;
      margin: 5px 5px 0 5px;
      font-size: 12px;
      font-weight: 300;
      line-height: 48px;
      -webkit-transition: .3s;
      transition: .3s;
      color: rgba(0, 0, 0, 0.87);
      border-radius: 3px; }
      .field-wrapper .autocomplete-dropdown p:last-child {
        margin-bottom: 5px; }
      .field-wrapper .autocomplete-dropdown p:hover {
        cursor: pointer;
        background: #2196f3;
        color: #fff; }
  .field-wrapper label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px; }
  .field-wrapper > svg {
    position: relative;
    margin: 20px auto 3px;
    -webkit-transition: .3s;
    transition: .3s; }
    .field-wrapper > svg.checked {
      color: #2196f3; }
    .field-wrapper > svg.unchecked {
      color: #aaaaaa; }
  .field-wrapper .MuiInput-input {
    height: 15px;
    font-size: 14px; }
  .field-wrapper .MuiInput-underline:before {
    display: none; }
  .field-wrapper .MuiInput-underline:after {
    display: none; }
  .field-wrapper .MuiInputBase-root.Mui-disabled:before {
    border-bottom-style: none;
    content: "";
    display: block;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 1px;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(0, 0, 0, 0.42)), color-stop(33%, rgba(0, 0, 0, 0.42)), color-stop(0, transparent));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0);
    background-size: 4px 100%, 100% 1px;
    background-repeat: repeat-x; }
  .field-wrapper .Mui-error .MuiInput-input {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f44336), to(#f44336)), -webkit-gradient(linear, left top, left bottom, from(#f44336), to(#f44336));
    background-image: linear-gradient(#f44336, #f44336), linear-gradient(#f44336, #f44336); }
    .field-wrapper .Mui-error .MuiInput-input:focus {
      background-image: -webkit-gradient(linear, left top, left bottom, from(#f44336), to(#f44336)), -webkit-gradient(linear, left top, left bottom, from(#f44336), to(#f44336));
      background-image: linear-gradient(#f44336, #f44336), linear-gradient(#f44336, #f44336); }
    .field-wrapper .Mui-error .MuiInput-input::-webkit-input-placeholder {
      /* Edge */
      color: #f44336; }
    .field-wrapper .Mui-error .MuiInput-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #f44336; }
    .field-wrapper .Mui-error .MuiInput-input::-moz-placeholder {
      color: #f44336; }
    .field-wrapper .Mui-error .MuiInput-input::-ms-input-placeholder {
      color: #f44336; }
    .field-wrapper .Mui-error .MuiInput-input::placeholder {
      color: #f44336; }
  .field-wrapper .MuiInputBase-multiline {
    padding: 2px 0 0px; }
  .field-wrapper .MuiInput-input {
    border: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2196f3), to(#2196f3)), -webkit-gradient(linear, left top, left bottom, from(#333333), to(#333333));
    background-image: linear-gradient(#2196f3, #2196f3), linear-gradient(#333333, #333333);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: background 0s ease-out;
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    width: 100%;
    padding: 6.125px 0;
    font-size: 14px;
    line-height: 1.428571429;
    display: block;
    width: 100%;
    color: #333333;
    line-height: 15.75px;
    position: relative; }
    .field-wrapper .MuiInput-input.Mui-disabled {
      background-image: none; }
  .field-wrapper .MuiInput-input:focus {
    outline: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2196f3), to(#2196f3)), -webkit-gradient(linear, left top, left bottom, from(#333333), to(#333333));
    background-image: linear-gradient(#2196f3, #2196f3), linear-gradient(#333333, #333333);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s; }
  .field-wrapper .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    position: absolute;
    bottom: 0;
    -webkit-transform: translateY(100%) translateY(5px);
            transform: translateY(100%) translateY(5px);
    font-size: 10.5px; }

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input.MuiInputBase-inputSelect {
  padding-right: 23px; }

.MuiInputLabel-root.Mui-focused {
  color: #2196f3 !important; }

.field-wrapper.center-field-mode {
  padding: 0 10px !important;
  margin-top: 0; }
  .field-wrapper.center-field-mode.without-label-border label {
    border-bottom: 0px solid #000;
    border-top: 0px solid #000; }
  .field-wrapper.center-field-mode label {
    width: calc(100% + 20px) !important;
    margin-left: -10px;
    align-content: center;
    justify-content: center;
    display: flex;
    font-size: 12px;
    font-style: italic;
    line-height: 24px;
    color: #000;
    padding: 5px;
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transform: none;
            transform: none;
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    box-sizing: border-box; }

.card-with-header {
  background: #fff;
  padding: 0 20px 20px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 4px 0px;
  border-radius: 6px; }

.card-header {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: -20px;
  margin-left: -5px;
  margin-right: -5px; }
  .card-header p {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
    color: #3c4858; }

.card-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-right: 15px;
  height: 64px;
  width: 64px;
  background: linear-gradient(60deg, #2196f3, #39a1f4);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgba(33, 150, 243, 0.4) 0px 7px 10px -5px; }
  .card-header-icon .material-icons {
    font-size: 24px;
    color: #fff; }

.card-body {
  padding-top: 18px; }

.error-message {
  background-color: #f55145;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #fff;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgba(244, 67, 54, 0.4) 0px 7px 10px -5px;
  padding: 20px 15px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px; }

.check-me-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px; }
  .check-me-wrapper .Mui-checked {
    color: #2196f3 !important; }

.reset-password-link {
  color: #2196f3;
  font-size: 14px;
  text-decoration: none;
  font-weight: 300;
  line-height: 21px; }

.checked-me-checkbox {
  margin-bottom: 17.5px !important;
  margin-top: 8px !important;
  padding-left: 11px; }
  .checked-me-checkbox .MuiFormControlLabel-label {
    color: #aaaaaa;
    font-size: 14px;
    line-height: 22px; }
  .checked-me-checkbox .MuiCheckbox-root {
    padding: 0;
    margin-right: 8px; }
    .checked-me-checkbox .MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked {
      color: #2196f3; }

.half-padding {
  width: calc(50% - 30px);
  margin: 13px 0 17.5px !important;
  padding: 0 15px; }


.dashboard-screen {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }
  .dashboard-screen .dashboard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    overflow-y: scroll;
    width: calc(100% - 260px);
    position: relative;
    z-index: 1; }
  .dashboard-screen .dashboard-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    z-index: 200; }
  .dashboard-screen .back-btn {
    background: #fff;
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    -webkit-transition: .3s;
    transition: .3s; }
    .dashboard-screen .back-btn .material-icons {
      font-size: 20px;
      color: #999999; }
    .dashboard-screen .back-btn:hover {
      box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2) !important; }
  .dashboard-screen .notification-btn {
    height: 50px;
    width: 50px;
    padding: 15px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer; }
    .dashboard-screen .notification-btn .material-icons {
      font-size: 20px;
      color: #555555 !important; }
    .dashboard-screen .notification-btn .count {
      position: absolute;
      top: 5px;
      border: 1px solid #fff;
      right: 7px;
      font-size: 9px;
      background: #f44336;
      color: #fff;
      min-width: 20px;
      padding: 0 5px;
      height: 20px;
      border-radius: 10px;
      text-align: center;
      line-height: 19px;
      vertical-align: middle;
      display: block;
      font-weight: 400;
      box-sizing: border-box; }

.notification-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 340px;
  z-index: 2000; }
  .notification-list-wrapper .see-all {
    padding: 10px 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #cccccc; }
    .notification-list-wrapper .see-all a {
      color: #2196f3;
      text-decoration: none; }
  .notification-list-wrapper .notification-compant {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    box-sizing: border-box; }
    .notification-list-wrapper .notification-compant .notification-compant-item {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 30px 10px 24px;
      background: #fff;
      -webkit-transition: .3s;
      transition: .3s;
      border-radius: 2px;
      position: relative; }
      .notification-list-wrapper .notification-compant .notification-compant-item:hover {
        background: #2196f3; }
        .notification-list-wrapper .notification-compant .notification-compant-item:hover button .material-icons {
          font-size: 20px;
          color: #fff; }
      .notification-list-wrapper .notification-compant .notification-compant-item > .material-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #004a93;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #fff;
        font-size: 14px;
        color: #fff;
        margin-right: 6px; }
      .notification-list-wrapper .notification-compant .notification-compant-item p {
        font-size: 13px;
        font-weight: 400px;
        line-height: 18px;
        color: #212529; }
      .notification-list-wrapper .notification-compant .notification-compant-item button {
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        background: none;
        border: none;
        outline: none;
        height: 20px;
        width: 20px;
        -webkit-transition: .3s;
        transition: .3s;
        padding: 0;
        border-radius: 2px;
        cursor: pointer; }
        .notification-list-wrapper .notification-compant .notification-compant-item button:hover {
          background: rgba(0, 0, 0, 0.1); }
        .notification-list-wrapper .notification-compant .notification-compant-item button .material-icons {
          font-size: 20px;
          color: #212529; }

.form-dialog-window {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column; }
  .form-dialog-window .form-dialog-header {
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    .form-dialog-window .form-dialog-header p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px; }
    .form-dialog-window .form-dialog-header button {
      background: transparent;
      border: none;
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0;
      color: #666;
      outline: none;
      cursor: pointer;
      -webkit-transition: .3s;
      transition: .3s; }
      .form-dialog-window .form-dialog-header button:hover {
        color: #999; }

.dialog-window {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box; }
  .dialog-window h4 {
    margin: 0;
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin-top: 4px;
    text-transform: uppercase; }
  .dialog-window p {
    display: block;
    position: relative;
    max-width: 100%;
    margin: 0 0 12px;
    padding: 0;
    color: #595959;
    line-height: 45px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
    max-width: 472px; }
    .dialog-window p.small-text {
      font-size: 18px;
      font-weight: 300;
      line-height: 25px;
      max-width: 95%; }
  .dialog-window .btn-block {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center; }

.sidebar {
  max-height: 100vh;
  height: 100vh;
  width: 260px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2; }

.divider {
  width: calc(100% - 30px);
  margin: 10px 15px 0;
  height: 1px;
  background: rgba(180, 180, 180, 0.3); }

.avatar-bar {
  width: calc(100% - 30px);
  margin: 12px 15px 0;
  padding: 8px 10px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  box-sizing: border-box; }
  .avatar-bar .avatar-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%; }
    .avatar-bar .avatar-wrapper .avatar {
      height: 32px;
      width: 32px;
      min-width: 32px;
      min-height: 32px;
      margin-right: 11px;
      box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      border: 1px solid #fff;
      background-size: 100%; }
    .avatar-bar .avatar-wrapper .profile-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: calc(100% - 65px); }
      .avatar-bar .avatar-wrapper .profile-info .profile-name {
        color: #3c4858;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%; }
      .avatar-bar .avatar-wrapper .profile-info .company-name {
        color: #3c4858;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-top: 2px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%; }

.dropdown-carret {
  position: absolute;
  right: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0 !important;
  height: 24px !important;
  width: 24px !important;
  -webkit-transform-origin: 12px 12px;
          transform-origin: 12px 12px;
  -webkit-transition: .3s;
  transition: .3s;
  color: #3c4858 !important;
  font-size: 19px !important;
  display: flex;
  justify-content: center;
  align-items: center; }
  .dropdown-carret.open {
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg); }

.menu {
  max-height: 100vh;
  height: 100vh;
  width: 260px;
  background: #fff;
  overflow-y: scroll;
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box; }

.menu-item {
  width: calc(100% - 30px);
  margin: 10px 15px 0;
  padding: 8px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 3px;
  position: relative;
  -webkit-transition: .3s;
  transition: .3s;
  cursor: pointer; }
  .menu-item:first-child {
    margin-top: 0; }
  .menu-item .letter-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 300;
    margin-right: 15px;
    line-height: 21px;
    text-align: center;
    color: #3c4858;
    text-transform: uppercase;
    min-width: 30px;
    width: 30px; }
  .menu-item .material-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    float: left;
    margin-right: 15px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    color: #a9afbb; }
  .menu-item p {
    margin: 0;
    width: 100%;
    line-height: 30px;
    font-size: 14px;
    position: relative;
    display: block;
    height: auto;
    white-space: nowrap;
    color: #3c4858;
    font-weight: 300;
    padding-right: 16px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden; }
  .menu-item.active {
    color: #3c4858;
    opacity: 1;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(60, 72, 88, 0.4); }
    .menu-item.active:hover {
      background-color: white;
      color: #3c4858;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(60, 72, 88, 0.4); }
  .menu-item:hover {
    background-color: rgba(200, 200, 200, 0.2);
    color: #3c4858;
    box-shadow: none; }

.submenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%; }
  .submenu .MuiCollapse-container {
    width: 100%; }
  .submenu .menu-item:first-child {
    margin-top: 15px; }
  .submenu .menu-item p {
    line-height: 21px;
    font-size: 13px; }


.pmu-request-list-title {
  position: relative;
  padding: 0 0 0 70px;
  text-align: center;
  display: inline-block;
  vertical-align: top; }

.pmu-request-list-title .title-icon {
  position: absolute;
  left: 0;
  top: 50%;
  margin: -25px 0 0;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  text-align: center;
  background-color: #d7d7d7;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.pmu-request-list-title .title-icon > .material-icons {
  line-height: 50px;
  color: #636363;
  height: 100%; }

.pmu-request-list-title .title-body {
  box-sizing: border-box;
  color: #3c4858;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  margin-bottom: 15px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  text-align: center;
  -webkit-text-decoration-color: #3c4858;
          text-decoration-color: #3c4858;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: none;
  transition-property: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.page-template {
  flex: 1 1;
  width: 100%;
  padding-bottom: 30px;
  min-width: 1350px; }

.grey-btn {
  border-radius: 40px;
  box-shadow: 0 0 0 1px #747474;
  height: 40px;
  width: 40px;
  padding: 0 !important;
  margin: 4px !important; }
  .grey-btn .material-icons {
    font-size: 20px !important; }

.grey-btn-full {
  color: #fff !important;
  border-radius: 40px;
  background: #999 !important;
  box-shadow: 0 0 0 1px #999;
  height: 40px;
  width: 40px;
  padding: 0 !important;
  margin: 4px !important; }
  .grey-btn-full .material-icons {
    font-size: 20px !important; }

.orange-btn-full {
  color: #fff !important;
  border-radius: 40px;
  background: #ff9800 !important;
  border-color: #ff9800;
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
  height: 40px;
  width: 40px;
  padding: 0 !important;
  margin: 4px !important; }
  .orange-btn-full .material-icons {
    font-size: 20px !important; }

.upside-down {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.content-right {
  display: flex;
  justify-content: flex-end;
  align-items: 'center'; }

.filter-block {
  margin-top: 24px;
  background: #fff;
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  padding: 24px 30px; }
  .filter-block .field-wrapper {
    flex: 1 1;
    margin: 0 15px;
    max-width: 300px;
    padding-bottom: 0; }
    .filter-block .field-wrapper:first-child {
      margin-left: 0; }
    .filter-block .field-wrapper:last-child {
      margin-right: 0; }

.add-button {
  background-color: #2196f3;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px 0px, rgba(0, 0, 0, 0.24) 0px 1px 2px 0px;
  border-radius: 50%;
  border: none;
  height: 56px;
  width: 56px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 9999;
  -webkit-transition: .3s;
  transition: .3s;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center; }
  .add-button:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .add-button .material-icons {
    color: #fff;
    font-size: 24px; }

.tab-wrapper {
  margin: 24px 30px;
  box-shadow: 0 0 0 1px #d7d7d7;
  border-radius: 3px;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex; }
  .tab-wrapper .tab-point {
    flex: 1 1;
    padding: 10px 15px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    border-right: 1px solid #d7d7d7;
    -webkit-transition: .3s;
    transition: .3s;
    cursor: pointer; }
    .tab-wrapper .tab-point:last-child {
      border-right: none; }
    .tab-wrapper .tab-point.active {
      background: #d7d7d7; }

.table-header-item {
  position: relative;
  padding-right: 18px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  color: #3c4858; }
  .table-header-item.asc .sort-arrow-down {
    opacity: 0; }
  .table-header-item.desc .sort-arrow-up {
    opacity: 0; }

.table-body-item {
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  color: #3c4858;
  line-height: 21px; }
  .table-body-item a {
    text-decoration: none;
    color: #2196f3; }
  .table-body-item.bold {
    font-weight: 700; }

.sort-arrow-up {
  -webkit-transition: .3s;
  transition: .3s;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) translateY(-3px);
          transform: translateY(-50%) translateY(-3px);
  font-size: 20px !important; }

.sort-arrow-down {
  -webkit-transition: .3s;
  transition: .3s;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) translateY(3px);
          transform: translateY(-50%) translateY(3px);
  font-size: 20px !important; }

.MuiTableCell-body button {
  background: none;
  height: 40px;
  width: 40px;
  padding: 0;
  border: none;
  margin: 0 10px;
  outline: none;
  cursor: pointer; }
  .MuiTableCell-body button:first-child {
    margin-left: 0; }
  .MuiTableCell-body button:last-child {
    margin-right: 0; }

.MuiTableCell-root {
  padding: 5px 10px !important; }

.MuiTableRow-root {
  min-height: 62px;
  height: 62px; }
  .MuiTableRow-root.MuiTableRow-head {
    min-height: 44px;
    height: 44px; }

.MuiTableCell-body .material-icons {
  color: #fff;
  background-color: #999;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 3px;
  font-size: 20px; }

.MuiTablePagination-root {
  padding-right: 50px !important; }

.no-data-block {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%; }
  .no-data-block p {
    color: #3c4858;
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;
    margin-top: 20px; }


.form-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #d7d7d7;
  width: 100%; }
  .form-block.compact-padding {
    padding: 13px 10px 5px; }
    .form-block.compact-padding .field-wrapper {
      margin-bottom: 8px; }
  .form-block.compact-style .form-block-title-part p {
    font-size: 12px; }
  .form-block.without-border {
    border-bottom: none; }
  .form-block .form-block-title-part {
    width: 33.3333%;
    margin-top: 13px; }
    .form-block .form-block-title-part p {
      width: 100%;
      padding-right: 15px;
      box-sizing: border-box;
      color: #3c4858;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px; }
  .form-block .form-block-content-part {
    width: calc(66.6667% + 30px);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: -15px;
    margin-left: -15px; }
    .form-block .form-block-content-part.content-center {
      justify-content: center; }

.simple-page-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 24px; }

.simple-page-card-form {
  margin: 0 15px;
  border-radius: 5px;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 70px; }

.simple-page-card-header {
  padding: 20px;
  box-sizing: border-box;
  color: #3c4858;
  border-bottom: 2px solid #d7d7d7;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px; }




.object-form-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 800px;
  flex-wrap: wrap; }
  .object-form-wrapper .object-form-col {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }



.password-strength-block {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .password-strength-block p {
    font-size: 11px;
    font-weight: 400;
    line-height: 21px;
    color: #333333; }
  .password-strength-block ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 15px;
    padding: 0; }
    .password-strength-block ul.level-0 li {
      background: #dddddd; }
    .password-strength-block ul.level-1 li:nth-child(1) {
      background: red; }
    .password-strength-block ul.level-2 li:nth-child(1) {
      background: #ffeb3b; }
    .password-strength-block ul.level-2 li:nth-child(2) {
      background: #ffeb3b; }
    .password-strength-block ul.level-3 li:nth-child(1) {
      background: #ff9900; }
    .password-strength-block ul.level-3 li:nth-child(2) {
      background: #ff9900; }
    .password-strength-block ul.level-3 li:nth-child(3) {
      background: #ff9900; }
    .password-strength-block ul.level-4 li:nth-child(1) {
      background: #99ff00; }
    .password-strength-block ul.level-4 li:nth-child(2) {
      background: #99ff00; }
    .password-strength-block ul.level-4 li:nth-child(3) {
      background: #99ff00; }
    .password-strength-block ul.level-4 li:nth-child(4) {
      background: #99ff00; }
    .password-strength-block ul.level-5 li {
      background: lime; }
    .password-strength-block ul li {
      width: 20px;
      height: 5px;
      border-radius: 2px;
      margin-right: 1px;
      background: #dddddd; }


.map-on-way {
  height: calc(100% - 60px);
  width: calc(100% - 30px);
  margin: 0px 15px 30px;
  border: none; }


.section-block {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin: 0 30px;
  line-height: 30px;
  margin-bottom: 24px; }
  .section-block .section-header {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #000000;
    color: #3c4858;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .section-block .section-header p {
      padding: 20px;
      box-sizing: border-box;
      flex: 1 1;
      border-left: 1px solid #000; }
      .section-block .section-header p:first-child {
        border: none; }
    .section-block .section-header.center-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%; }
  .section-block .section-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    align-items: stretch; }
  .section-block .half-block {
    flex: 1 0;
    border-left: 1px solid #000; }
    .section-block .half-block:first-child {
      border: none;
      border-right: 1px solid #000;
      margin-right: -1px; }

.array-section {
  width: 100%;
  border-top: 2px solid #d7d7d7; }
  .array-section:first-of-type {
    border: none; }

.i-point-checkbox {
  padding: 5px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  box-sizing: border-box; }
  .i-point-checkbox p {
    font-size: 14px;
    line-height: 40px;
    color: #3c4858; }
  .i-point-checkbox .point-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 24px;
    cursor: pointer; }
    .i-point-checkbox .point-checkbox span {
      position: relative;
      height: 16px;
      width: 16px;
      border: 2px solid rgba(0, 0, 0, 0.54);
      border-radius: 50%;
      -webkit-transition: .3s;
      transition: .3s; }
      .i-point-checkbox .point-checkbox span:before {
        content: "";
        height: 10px;
        width: 10px;
        -webkit-transition: .3s;
        transition: .3s;
        background: transparent;
        display: block;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        border-radius: 50%; }
    .i-point-checkbox .point-checkbox p {
      padding-left: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #aaaaaa; }
    .i-point-checkbox .point-checkbox.active span {
      border: 2px solid #2196f3; }
      .i-point-checkbox .point-checkbox.active span:before {
        background: #2196f3; }

.add-block-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-top: 2px solid #d7d7d7;
  box-sizing: border-box; }
  .add-block-line div {
    margin: 5px 0;
    height: 41px;
    width: 41px;
    border-radius: 50%;
    background-color: #999999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12); }
    .add-block-line div:hover {
      box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2); }
    .add-block-line div .material-icons {
      font-size: 20px;
      color: #fff; }
  .add-block-line p {
    color: #000;
    font-size: 14px;
    font-weight: 700; }

.fields-section {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -1px;
  position: relative; }
  .fields-section .MuiButtonBase-root {
    margin-top: 35px !important; }
  .fields-section.top-sparator {
    border-top: 1px solid #b8b8b8; }
    .fields-section.top-sparator:first-child {
      border-top: none; }
  .fields-section.padding {
    padding: 15px 0;
    box-sizing: border-box; }
  .fields-section.line:before {
    content: "";
    display: block;
    position: absolute;
    top: 35px;
    left: 0;
    height: 0px;
    width: 100%;
    border-top: 1px solid #000; }
  .fields-section.line::after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    height: 0px;
    width: 100%;
    border-top: 1px solid #000; }
  .fields-section.line:first-of-type .delete-row-btn {
    top: 68%; }
  .fields-section label + .MuiInput-formControl {
    margin-top: 0px; }
  .fields-section .MuiInput-formControl {
    margin-top: 16px !important; }

.delete-row-btn {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0px 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12); }
  .delete-row-btn:hover {
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2); }
  .delete-row-btn.disable {
    cursor: no-drop; }
  .delete-row-btn .material-icons {
    font-size: 17px;
    color: #fff; }

.roads-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%; }
  .roads-block p {
    font-size: 14px;
    line-height: 56px;
    color: #3c4858;
    margin-right: 35px; }
  .roads-block .roads-fields {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
    .roads-block .roads-fields label + .MuiInput-formControl {
      margin-top: 0px; }
    .roads-block .roads-fields .MuiInput-formControl {
      margin-top: 16px !important; }

.road-field {
  position: relative;
  margin-right: 40px; }
  .road-field .delete-row-btn {
    right: 0;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }

.routes-btns-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }


.title-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 35%;
  margin-top: -32px;
  margin-bottom: 24px;
  margin-left: 101px; }
  .title-info p {
    color: #3c4858;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300; }

.array-section {
  border-top: 2px solid #d7d7d7; }
  .array-section:first-of-type {
    border: none; }

.i-point-checkbox {
  padding: 5px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  box-sizing: border-box; }
  .i-point-checkbox p {
    font-size: 14px;
    line-height: 40px;
    color: #3c4858; }
  .i-point-checkbox .point-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 24px;
    cursor: pointer; }
    .i-point-checkbox .point-checkbox span {
      position: relative;
      height: 16px;
      width: 16px;
      border: 2px solid rgba(0, 0, 0, 0.54);
      border-radius: 50%;
      -webkit-transition: .3s;
      transition: .3s; }
      .i-point-checkbox .point-checkbox span:before {
        content: "";
        height: 10px;
        width: 10px;
        -webkit-transition: .3s;
        transition: .3s;
        background: transparent;
        display: block;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        border-radius: 50%; }
    .i-point-checkbox .point-checkbox p {
      padding-left: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #aaaaaa; }
    .i-point-checkbox .point-checkbox.active span {
      border: 2px solid #2196f3; }
      .i-point-checkbox .point-checkbox.active span:before {
        background: #2196f3; }

.add-block-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-top: 2px solid #d7d7d7;
  box-sizing: border-box; }
  .add-block-line div {
    margin: 5px 0;
    height: 41px;
    width: 41px;
    border-radius: 50%;
    background-color: #999999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12); }
    .add-block-line div:hover {
      box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2); }
    .add-block-line div .material-icons {
      font-size: 20px;
      color: #fff; }
  .add-block-line p {
    color: #000;
    font-size: 14px;
    font-weight: 700; }

.fields-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -1px;
  position: relative;
  width: 100%; }
  .fields-section.line:before {
    content: "";
    display: block;
    position: absolute;
    top: 35px;
    left: 0;
    height: 0px;
    width: 100%;
    border-top: 1px solid #000; }
  .fields-section.line::after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    height: 0px;
    width: 100%;
    border-top: 1px solid #000; }
  .fields-section.line:first-of-type .delete-row-btn {
    top: 68%; }
  .fields-section label + .MuiInput-formControl {
    margin-top: 0px; }
  .fields-section .MuiInput-formControl {
    margin-top: 16px !important; }

.delete-row-btn {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0px 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12); }
  .delete-row-btn:hover {
    box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2); }
  .delete-row-btn.disable {
    cursor: no-drop; }
  .delete-row-btn .material-icons {
    font-size: 17px;
    color: #fff; }

.roads-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px;
  box-sizing: border-box; }
  .roads-block p {
    font-size: 14px;
    line-height: 56px;
    color: #3c4858;
    margin-right: 35px; }
  .roads-block .roads-fields {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
    .roads-block .roads-fields label + .MuiInput-formControl {
      margin-top: 0px; }
    .roads-block .roads-fields .MuiInput-formControl {
      margin-top: 16px !important; }

.road-field {
  position: relative;
  margin-right: 40px; }
  .road-field .delete-row-btn {
    right: 0;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }

.routes-btns-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.steps-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  box-sizing: border-box;
  margin-bottom: 24px; }
  .steps-tab .steps-tab-item {
    text-align: center;
    text-transform: uppercase;
    padding: 5px;
    background-color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid #d7d7d7;
    border-left: none;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    color: #3c4858;
    width: 33.3%; }
    .steps-tab .steps-tab-item.active {
      background-color: #d7d7d7; }
    .steps-tab .steps-tab-item:first-child {
      border-left: 1px solid #d7d7d7;
      border-radius: 5px 0 0 5px; }
    .steps-tab .steps-tab-item:last-child {
      border-radius: 0 5px 5px 0; }

.routes-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
  width: 50%; }
  .routes-list p {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    margin-right: 5px; }

.no-data-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #3c4858;
  padding: 24px;
  box-sizing: border-box; }

.order-price-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%; }
  .order-price-block > div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .order-price-block > div.result {
      padding: 52px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box; }
    .order-price-block > div.price {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: #d7d7d7 -1px 0px 0px 0px;
      height: 100%;
      padding: 15px 0; }
      .order-price-block > div.price .field-wrapper.center-field-mode {
        padding: 0px !important; }
        .order-price-block > div.price .field-wrapper.center-field-mode input {
          font-size: 18px; }
      .order-price-block > div.price > p {
        margin-bottom: 27px; }
    .order-price-block > div .result p, .order-price-block > div .price p {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px; }

.wright-comment-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 270px;
  box-sizing: border-box;
  padding-bottom: 22.5px;
  border-bottom: 1px solid #000;
  width: 100%;
  margin-bottom: 10px; }
  .wright-comment-block .material-icons {
    background-color: #d3d3d3;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6);
    border-radius: 100%;
    box-sizing: border-box;
    padding: 6px;
    height: 36px;
    width: 36px;
    position: relative;
    z-index: 1;
    color: #3c4858;
    margin: 0 50px;
    position: relative;
    overflow: unset; }
  .wright-comment-block .field-wrapper {
    width: 645px;
    margin-bottom: 0px; }
  .wright-comment-block .btn-wright-wrapper {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-end; }

.event-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 8px 15px;
  box-sizing: border-box; }
  .event-list.without-lines .status .material-icons:before {
    height: 0; }
  .event-list .time {
    width: 255px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .event-list .time p {
      font-size: 12px;
      line-height: 15px;
      color: #000;
      font-weight: 300; }
  .event-list:first-child {
    padding-top: 20px; }
  .event-list:last-child {
    padding-bottom: 20px; }
    .event-list:last-child .status .material-icons:before {
      height: 0; }
  .event-list .status {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .event-list .status .material-icons {
      background-color: #d3d3d3;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6);
      border-radius: 100%;
      box-sizing: border-box;
      padding: 6px;
      height: 36px;
      width: 36px;
      position: relative;
      z-index: 1;
      color: #3c4858;
      margin: 0 50px;
      position: relative;
      overflow: unset; }
      .event-list .status .material-icons:before {
        content: "";
        display: block;
        position: absolute;
        height: 19px;
        width: 2px;
        background: #d3d3d3;
        bottom: -39px;
        left: 50%;
        -webkit-transform: translate(-50%, -100%);
                transform: translate(-50%, -100%); }
    .event-list .status p {
      background-color: #eee;
      border-radius: 3px;
      padding: 10px 20px;
      color: #000;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      width: 645px;
      word-break: break-all; }
  .event-list > p {
    font-size: 12px;
    line-height: 15px;
    color: #000;
    font-weight: 300;
    width: 330px;
    text-align: center;
    flex: 1 1; }

.msg {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  padding: 24px;
  box-sizing: border-box;
  font-weight: 300; }

.half-block {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }

.command-panel-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 13px 0px;
  box-sizing: border-box; }

.route-list-btn-wrapper {
  margin-top: 17.5px;
  width: 50%; }

.status-checker {
  padding: 10px 20px;
  color: #3c4858;
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
  border-left: 5px solid #673ab7;
  margin-left: 20px;
  border-radius: 5px;
  background: #fff;
  max-width: 200px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .status-checker.FORMED {
    border-color: #4caf50; }
  .status-checker.UNDER_CONSIDERATION {
    border-color: #f9a825; }
  .status-checker.REJECTED {
    border-color: #f44336; }
  .status-checker.AGREED {
    border-color: #673ab7; }
  .status-checker.ON_ROUTE {
    border-color: #6d4c41; }
  .status-checker.COMPLETED {
    border-color: #0077ff; }
  .status-checker.ARCHIEVED {
    border-color: #607d8b; }
  .status-checker.CHANGED {
    border-color: #607d8b; }

.price-act-result-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%; }

.act-price-nds-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%; }
  .act-price-nds-block p {
    font-size: 17px;
    font-weight: 700;
    color: #3c4858;
    padding: 20px;
    line-height: 21px;
    min-width: 160px;
    text-align: center; }

.act-price-result-block {
  border-top: 2px solid gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .act-price-result-block p {
    font-size: 24px;
    font-weight: 700;
    color: #3c4858;
    padding: 20px;
    line-height: 21px;
    min-width: 160px;
    text-align: center; }

.act-btn-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px; }




.password-strength-block {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .password-strength-block p {
    font-size: 11px;
    font-weight: 400;
    line-height: 21px;
    color: #333333; }
  .password-strength-block ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 15px;
    padding: 0; }
    .password-strength-block ul.level-0 li {
      background: #dddddd; }
    .password-strength-block ul.level-1 li:nth-child(1) {
      background: red; }
    .password-strength-block ul.level-2 li:nth-child(1) {
      background: #ffeb3b; }
    .password-strength-block ul.level-2 li:nth-child(2) {
      background: #ffeb3b; }
    .password-strength-block ul.level-3 li:nth-child(1) {
      background: #ff9900; }
    .password-strength-block ul.level-3 li:nth-child(2) {
      background: #ff9900; }
    .password-strength-block ul.level-3 li:nth-child(3) {
      background: #ff9900; }
    .password-strength-block ul.level-4 li:nth-child(1) {
      background: #99ff00; }
    .password-strength-block ul.level-4 li:nth-child(2) {
      background: #99ff00; }
    .password-strength-block ul.level-4 li:nth-child(3) {
      background: #99ff00; }
    .password-strength-block ul.level-4 li:nth-child(4) {
      background: #99ff00; }
    .password-strength-block ul.level-5 li {
      background: lime; }
    .password-strength-block ul li {
      width: 20px;
      height: 5px;
      border-radius: 2px;
      margin-right: 1px;
      background: #dddddd; }

@-webkit-keyframes swal2-show {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7); }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes swal2-show {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7); }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes swal2-hide {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0; } }

@keyframes swal2-hide {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0; } }

@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .875em;
    width: 1.5625em; } }

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .875em;
    width: 1.5625em; } }

@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@-webkit-keyframes swal2-rotate-success-circular-line {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); } }

@keyframes swal2-rotate-success-circular-line {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); } }

@-webkit-keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    -webkit-transform: scale(1.15);
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    -webkit-transform: scale(1.15);
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes swal2-animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1; } }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast {
  flex-direction: column;
  align-items: stretch; }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-actions {
  flex: 1 1;
  align-self: stretch;
  justify-content: flex-end;
  height: 2.2em; }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-loading {
  justify-content: center; }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-input {
  height: 2em;
  margin: .3125em auto;
  font-size: 1em; }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-validationerror {
  font-size: 1em; }

body.swal2-toast-shown > .swal2-container {
  position: fixed;
  background-color: transparent; }

body.swal2-toast-shown > .swal2-container.swal2-shown {
  background-color: transparent; }

body.swal2-toast-shown > .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

body.swal2-toast-shown > .swal2-container.swal2-top-end, body.swal2-toast-shown > .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto; }

body.swal2-toast-shown > .swal2-container.swal2-top-left, body.swal2-toast-shown > .swal2-container.swal2-top-start {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0; }

body.swal2-toast-shown > .swal2-container.swal2-center-left, body.swal2-toast-shown > .swal2-container.swal2-center-start {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

body.swal2-toast-shown > .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

body.swal2-toast-shown > .swal2-container.swal2-center-end, body.swal2-toast-shown > .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

body.swal2-toast-shown > .swal2-container.swal2-bottom-left, body.swal2-toast-shown > .swal2-container.swal2-bottom-start {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0; }

body.swal2-toast-shown > .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

body.swal2-toast-shown > .swal2-container.swal2-bottom-end, body.swal2-toast-shown > .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto; }

.swal2-popup.swal2-toast {
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: .625em;
  box-shadow: 0 0 .625em #d9d9d9;
  overflow-y: hidden; }

.swal2-popup.swal2-toast .swal2-header {
  flex-direction: row; }

.swal2-popup.swal2-toast .swal2-title {
  justify-content: flex-start;
  margin: 0 .6em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-close {
  position: static;
  position: initial; }

.swal2-popup.swal2-toast .swal2-content {
  justify-content: flex-start;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0; }

.swal2-popup.swal2-toast .swal2-icon-text {
  font-size: 2em;
  font-weight: 700;
  line-height: 1em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: .875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: .3125em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: .3125em; }

.swal2-popup.swal2-toast .swal2-actions {
  height: auto;
  margin: 0 .3125em; }

.swal2-popup.swal2-toast .swal2-styled {
  margin: 0 .3125em;
  padding: .3125em .625em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 0.0625em #fff, 0 0 0 0.125em rgba(50, 100, 150, 0.4); }

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 2em;
  height: 2.8125em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.25em;
  left: -.9375em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 2em 2em;
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.25em;
  left: .9375em;
  -webkit-transform-origin: 0 2em;
  transform-origin: 0 2em;
  border-radius: 0 4em 4em 0; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: .4375em;
  width: .4375em;
  height: 2.6875em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: .3125em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: .1875em;
  width: .75em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: .9375em;
  right: .1875em;
  width: 1.375em; }

.swal2-popup.swal2-toast.swal2-show {
  -webkit-animation: showSweetToast .5s;
  animation: showSweetToast .5s; }

.swal2-popup.swal2-toast.swal2-hide {
  -webkit-animation: hideSweetToast .2s forwards;
  animation: hideSweetToast .2s forwards; }

.swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-tip {
  -webkit-animation: animate-toast-success-tip .75s;
  animation: animate-toast-success-tip .75s; }

.swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-long {
  -webkit-animation: animate-toast-success-long .75s;
  animation: animate-toast-success-long .75s; }

@-webkit-keyframes showSweetToast {
  0% {
    -webkit-transform: translateY(-0.625em) rotateZ(2deg);
    transform: translateY(-0.625em) rotateZ(2deg);
    opacity: 0; }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
    opacity: .5; }
  66% {
    -webkit-transform: translateY(0.3125em) rotateZ(2deg);
    transform: translateY(0.3125em) rotateZ(2deg);
    opacity: .7; }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
    opacity: 1; } }

@keyframes showSweetToast {
  0% {
    -webkit-transform: translateY(-0.625em) rotateZ(2deg);
    transform: translateY(-0.625em) rotateZ(2deg);
    opacity: 0; }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
    opacity: .5; }
  66% {
    -webkit-transform: translateY(0.3125em) rotateZ(2deg);
    transform: translateY(0.3125em) rotateZ(2deg);
    opacity: .7; }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
    opacity: 1; } }

@-webkit-keyframes hideSweetToast {
  0% {
    opacity: 1; }
  33% {
    opacity: .5; }
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0; } }

@keyframes hideSweetToast {
  0% {
    opacity: 1; }
  33% {
    opacity: .5; }
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0; } }

@-webkit-keyframes animate-toast-success-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@keyframes animate-toast-success-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@-webkit-keyframes animate-toast-success-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@keyframes animate-toast-success-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow-y: hidden; }

body.swal2-height-auto {
  height: auto !important; }

body.swal2-no-backdrop .swal2-shown {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  background-color: transparent; }

body.swal2-no-backdrop .swal2-shown > .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }

body.swal2-no-backdrop .swal2-shown.swal2-top {
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

body.swal2-no-backdrop .swal2-shown.swal2-top-left, body.swal2-no-backdrop .swal2-shown.swal2-top-start {
  top: 0;
  left: 0; }

body.swal2-no-backdrop .swal2-shown.swal2-top-end, body.swal2-no-backdrop .swal2-shown.swal2-top-right {
  top: 0;
  right: 0; }

body.swal2-no-backdrop .swal2-shown.swal2-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

body.swal2-no-backdrop .swal2-shown.swal2-center-left, body.swal2-no-backdrop .swal2-shown.swal2-center-start {
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

body.swal2-no-backdrop .swal2-shown.swal2-center-end, body.swal2-no-backdrop .swal2-shown.swal2-center-right {
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

body.swal2-no-backdrop .swal2-shown.swal2-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

body.swal2-no-backdrop .swal2-shown.swal2-bottom-left, body.swal2-no-backdrop .swal2-shown.swal2-bottom-start {
  bottom: 0;
  left: 0; }

body.swal2-no-backdrop .swal2-shown.swal2-bottom-end, body.swal2-no-backdrop .swal2-shown.swal2-bottom-right {
  right: 0;
  bottom: 0; }

.swal2-container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: transparent;
  z-index: 1060;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

.swal2-container.swal2-top {
  align-items: flex-start; }

.swal2-container.swal2-top-left, .swal2-container.swal2-top-start {
  align-items: flex-start;
  justify-content: flex-start; }

.swal2-container.swal2-top-end, .swal2-container.swal2-top-right {
  align-items: flex-start;
  justify-content: flex-end; }

.swal2-container.swal2-center {
  align-items: center; }

.swal2-container.swal2-center-left, .swal2-container.swal2-center-start {
  align-items: center;
  justify-content: flex-start; }

.swal2-container.swal2-center-end, .swal2-container.swal2-center-right {
  align-items: center;
  justify-content: flex-end; }

.swal2-container.swal2-bottom {
  align-items: flex-end; }

.swal2-container.swal2-bottom-left, .swal2-container.swal2-bottom-start {
  align-items: flex-end;
  justify-content: flex-start; }

.swal2-container.swal2-bottom-end, .swal2-container.swal2-bottom-right {
  align-items: flex-end;
  justify-content: flex-end; }

.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  display: flex !important;
  flex: 1 1;
  align-self: stretch;
  justify-content: center; }

.swal2-container.swal2-grow-row > .swal2-modal {
  display: flex !important;
  flex: 1 1;
  align-content: center;
  justify-content: center; }

.swal2-container.swal2-grow-column {
  flex: 1 1;
  flex-direction: column; }

.swal2-container.swal2-grow-column.swal2-bottom, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-top {
  align-items: center; }

.swal2-container.swal2-grow-column.swal2-bottom-left, .swal2-container.swal2-grow-column.swal2-bottom-start, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-center-start, .swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-top-start {
  align-items: flex-start; }

.swal2-container.swal2-grow-column.swal2-bottom-end, .swal2-container.swal2-grow-column.swal2-bottom-right, .swal2-container.swal2-grow-column.swal2-center-end, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-top-end, .swal2-container.swal2-grow-column.swal2-top-right {
  align-items: flex-end; }

.swal2-container.swal2-grow-column > .swal2-modal {
  display: flex !important;
  flex: 1 1;
  align-content: center;
  justify-content: center; }

.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right) > .swal2-modal {
  margin: auto; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important; } }

.swal2-container.swal2-fade {
  -webkit-transition: background-color .1s;
  transition: background-color .1s; }

.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.4); }

.swal2-popup {
  display: none;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border-radius: .3125em;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box; }

.swal2-popup:focus {
  outline: 0; }

.swal2-popup.swal2-loading {
  overflow-y: hidden; }

.swal2-popup .swal2-header {
  display: flex;
  flex-direction: column;
  align-items: center; }

.swal2-popup .swal2-title {
  display: block;
  position: relative;
  max-width: 100%;
  margin: 0 0 .4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word; }

.swal2-popup .swal2-actions {
  align-items: center;
  justify-content: center;
  margin: 1.25em auto 0; }

.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4; }

.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }

.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }

.swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  width: 2.5em;
  height: 2.5em;
  margin: .46875em;
  padding: 0;
  border: .25em solid transparent;
  border-radius: 100%;
  border-color: transparent;
  background-color: transparent !important;
  color: transparent;
  cursor: default;
  box-sizing: border-box;
  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-cancel {
  margin-right: 30px;
  margin-left: 30px; }

.swal2-popup .swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border: 3px solid #999;
  border-radius: 50%;
  border-right-color: transparent;
  box-shadow: 1px 1px 1px #fff;
  content: '';
  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal; }

.swal2-popup .swal2-styled {
  margin: 0 .3125em;
  padding: .625em 2em;
  font-weight: 500;
  box-shadow: none; }

.swal2-popup .swal2-styled:not([disabled]) {
  cursor: pointer; }

.swal2-popup .swal2-styled.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  background-color: #3085d6;
  color: #fff;
  font-size: 1.0625em; }

.swal2-popup .swal2-styled.swal2-cancel {
  border: 0;
  border-radius: .25em;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  background-color: #aaa;
  color: #fff;
  font-size: 1.0625em; }

.swal2-popup .swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(50, 100, 150, 0.4); }

.swal2-popup .swal2-styled::-moz-focus-inner {
  border: 0; }

.swal2-popup .swal2-footer {
  justify-content: center;
  margin: 1.25em 0 0;
  padding-top: 1em;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em; }

.swal2-popup .swal2-image {
  max-width: 100%;
  margin: 1.25em auto; }

.swal2-popup .swal2-close {
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  -webkit-transition: color .1s ease-out;
  transition: color .1s ease-out;
  border: none;
  border-radius: 0;
  background: 0 0;
  color: #ccc;
  font-family: serif;
  font-size: 2.5em;
  line-height: 1.2;
  cursor: pointer;
  overflow: hidden; }

.swal2-popup .swal2-close:hover {
  -webkit-transform: none;
  transform: none;
  color: #f27474; }

.swal2-popup > .swal2-checkbox, .swal2-popup > .swal2-file, .swal2-popup > .swal2-input, .swal2-popup > .swal2-radio, .swal2-popup > .swal2-select, .swal2-popup > .swal2-textarea {
  display: none; }

.swal2-popup .swal2-content {
  justify-content: center;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 1.125em;
  font-weight: 300;
  line-height: normal;
  word-wrap: break-word; }

.swal2-popup #swal2-content {
  text-align: center; }

.swal2-popup .swal2-checkbox, .swal2-popup .swal2-file, .swal2-popup .swal2-input, .swal2-popup .swal2-radio, .swal2-popup .swal2-select, .swal2-popup .swal2-textarea {
  margin: 1em auto; }

.swal2-popup .swal2-file, .swal2-popup .swal2-input, .swal2-popup .swal2-textarea {
  width: 100%;
  -webkit-transition: border-color .3s,box-shadow .3s;
  transition: border-color .3s,box-shadow .3s;
  border: 1px solid #d9d9d9;
  border-radius: .1875em;
  font-size: 1.125em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  box-sizing: border-box; }

.swal2-popup .swal2-file.swal2-inputerror, .swal2-popup .swal2-input.swal2-inputerror, .swal2-popup .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important; }

.swal2-popup .swal2-file:focus, .swal2-popup .swal2-input:focus, .swal2-popup .swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: 0;
  box-shadow: 0 0 3px #c4e6f5; }

.swal2-popup .swal2-file::-webkit-input-placeholder, .swal2-popup .swal2-input::-webkit-input-placeholder, .swal2-popup .swal2-textarea::-webkit-input-placeholder {
  color: #ccc; }

.swal2-popup .swal2-file:-ms-input-placeholder, .swal2-popup .swal2-input:-ms-input-placeholder, .swal2-popup .swal2-textarea:-ms-input-placeholder {
  color: #ccc; }

.swal2-popup .swal2-file::-ms-input-placeholder, .swal2-popup .swal2-input::-ms-input-placeholder, .swal2-popup .swal2-textarea::-ms-input-placeholder {
  color: #ccc; }

.swal2-popup .swal2-file::-moz-placeholder, .swal2-popup .swal2-input::-moz-placeholder, .swal2-popup .swal2-textarea::-moz-placeholder {
  color: #ccc; }

.swal2-popup .swal2-file::placeholder, .swal2-popup .swal2-input::placeholder, .swal2-popup .swal2-textarea::placeholder {
  color: #ccc; }

.swal2-popup .swal2-range input {
  width: 80%; }

.swal2-popup .swal2-range output {
  width: 20%;
  font-weight: 600;
  text-align: center; }

.swal2-popup .swal2-range input, .swal2-popup .swal2-range output {
  height: 2.625em;
  margin: 1em auto;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em; }

.swal2-popup .swal2-input {
  height: 2.625em;
  padding: .75em; }

.swal2-popup .swal2-input[type=number] {
  max-width: 10em; }

.swal2-popup .swal2-file {
  font-size: 1.125em; }

.swal2-popup .swal2-textarea {
  height: 6.75em;
  padding: .75em; }

.swal2-popup .swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  color: #545454;
  font-size: 1.125em; }

.swal2-popup .swal2-checkbox, .swal2-popup .swal2-radio {
  align-items: center;
  justify-content: center; }

.swal2-popup .swal2-checkbox label, .swal2-popup .swal2-radio label {
  margin: 0 .6em;
  font-size: 1.125em; }

.swal2-popup .swal2-checkbox input, .swal2-popup .swal2-radio input {
  margin: 0 .4em; }

.swal2-popup .swal2-validationerror {
  display: none;
  align-items: center;
  justify-content: center;
  padding: .625em;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: 300;
  overflow: hidden; }

.swal2-popup .swal2-validationerror::before {
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
  content: '!';
  zoom: normal; }

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important; }
  .swal2-range output {
    display: none; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important; }
  .swal2-range output {
    display: none; } }

@-moz-document url-prefix() {
  .swal2-close:focus {
    outline: 2px solid rgba(50, 100, 150, 0.4); } }

.swal2-icon {
  position: relative;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: .25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  box-sizing: content-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  zoom: normal; }

.swal2-icon-text {
  font-size: 3.75em; }

.swal2-icon.swal2-error {
  border-color: #f27474; }

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: #f27474; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86; }

.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee; }

.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd; }

.swal2-icon.swal2-success {
  border-color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.4375em;
  left: -2.0635em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 3.75em 3.75em;
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.6875em;
  left: 1.875em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 3.75em;
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0; }

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  top: -.25em;
  left: -.25em;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
  z-index: 2;
  box-sizing: content-box; }

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: 1; }

.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  height: .3125em;
  border-radius: .125em;
  background-color: #a5dc86;
  z-index: 2; }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: .875em;
  width: 1.5625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.swal2-progresssteps {
  align-items: center;
  margin: 0 0 1.25em;
  padding: 0;
  font-weight: 600; }

.swal2-progresssteps li {
  display: inline-block;
  position: relative; }

.swal2-progresssteps .swal2-progresscircle {
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #3085d6;
  color: #fff;
  line-height: 2em;
  text-align: center;
  z-index: 20; }

.swal2-progresssteps .swal2-progresscircle:first-child {
  margin-left: 0; }

.swal2-progresssteps .swal2-progresscircle:last-child {
  margin-right: 0; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
  background: #3085d6; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle {
  background: #add8e6; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
  background: #add8e6; }

.swal2-progresssteps .swal2-progressline {
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
  background: #3085d6;
  z-index: 10; }

[class^=swal2] {
  -webkit-tap-highlight-color: transparent; }

.swal2-show {
  -webkit-animation: swal2-show .3s;
  animation: swal2-show .3s; }

.swal2-show.swal2-noanimation {
  -webkit-animation: none;
  animation: none; }

.swal2-hide {
  -webkit-animation: swal2-hide .15s forwards;
  animation: swal2-hide .15s forwards; }

.swal2-hide.swal2-noanimation {
  -webkit-animation: none;
  animation: none; }

[dir=rtl] .swal2-close {
  right: auto;
  left: 0; }

.swal2-animate-success-icon .swal2-success-line-tip {
  -webkit-animation: swal2-animate-success-line-tip .75s;
  animation: swal2-animate-success-line-tip .75s; }

.swal2-animate-success-icon .swal2-success-line-long {
  -webkit-animation: swal2-animate-success-line-long .75s;
  animation: swal2-animate-success-line-long .75s; }

.swal2-animate-success-icon .swal2-success-circular-line-right {
  -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
  animation: swal2-rotate-success-circular-line 4.25s ease-in; }

.swal2-animate-error-icon {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-animate-error-icon .swal2-x-mark {
  -webkit-animation: swal2-animate-error-x-mark .5s;
  animation: swal2-animate-error-x-mark .5s; }

@-webkit-keyframes swal2-rotate-loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes swal2-rotate-loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.error-dialog-window {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px; }
  .error-dialog-window p {
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
    color: #545454; }
  .error-dialog-window button {
    margin: 20px 192px 0 !important; }

.swal2-x-mark {
  border: 4px solid #f27474;
  height: 80px;
  width: 80px;
  position: relative;
  border-radius: 44px;
  margin: 20px 192px 40px; }
  .swal2-x-mark .swal2-x-mark-line-left {
    position: absolute;
    width: 47px;
    height: 5px;
    background: #f27474;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg);
    border-radius: 2px; }
  .swal2-x-mark .swal2-x-mark-line-right {
    position: absolute;
    width: 47px;
    height: 5px;
    background: #f27474;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 2px; }

.warning-dialog-window {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px; }
  .warning-dialog-window p {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    color: #595959;
    margin-bottom: 12px; }
    .warning-dialog-window p.smart {
      color: #545454;
      font-size: 18px;
      font-weight: 300;
      line-height: 21px; }
  .warning-dialog-window .btn-line {
    margin: 20px 0px 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }

.swal2-warning {
  border: 4px solid #facea8;
  color: #f8bb86;
  height: 80px;
  width: 80px;
  position: relative;
  border-radius: 44px;
  margin: 20px 192px 30px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .swal2-warning .swal2-icon-text {
    font-size: 60px;
    font-weight: 300;
    line-height: 80px; }

