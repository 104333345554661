.sidebar{
    max-height: 100vh;
    height: 100vh;
    width: 260px;
    background: #fff;
    // overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
    position: relative;
    z-index: 2;
}

.divider{
    width: calc(100% - 30px);
    margin: 10px 15px 0;
    height: 1px;
    background: rgba(180,180,180,.3);
}


.avatar-bar{
    width: calc(100% - 30px);
    margin:  12px 15px 0;
    padding: 8px 10px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    .avatar-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        .avatar{
            height: 32px;
            width: 32px;
            min-width: 32px;
            min-height: 32px;
            margin-right: 11px;
            box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
            border-radius: 50%;
            border: 1px solid #fff;
            background-size: 100%;
        }
        .profile-info{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: calc(100% - 65px);
            .profile-name{
                color: rgb(60, 72, 88);
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
            }
            .company-name{
                color: rgb(60, 72, 88);
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                margin-top: 2px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
            }
        }
    }
}

.dropdown-carret{
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
    height: 24px !important;
    width: 24px !important;
    transform-origin: 12px 12px;
    transition: .3s;
    color: rgb(60, 72, 88) !important;
    font-size: 19px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    &.open{
        transform: translateY(-50%) rotate(180deg);
    }
}

.menu{
    max-height: 100vh;
    height: 100vh;
    width: 260px;
    background: #fff;
    overflow-y: scroll;
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}

.menu-item{
    width: calc(100% - 30px);
    margin: 10px 15px 0;
    padding: 8px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 3px;
    position: relative;
    transition: .3s;
    cursor: pointer;
    &:first-child{
        margin-top: 0;
    }
    .letter-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 300;
        margin-right: 15px;
        line-height: 21px;
        text-align: center;
        color: rgb(60, 72, 88);
        text-transform: uppercase;
        min-width: 30px;
        width: 30px;
    }
    .material-icons{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        float: left;
        margin-right: 15px;
        line-height: 30px;
        width: 30px;
        text-align: center;
        color: rgb(169, 175, 187);
    }
    p{
        margin: 0;
        width: 100%;
        line-height: 30px;
        font-size: 14px;
        position: relative;
        display: block;
        height: auto;
        white-space: nowrap;
        color: rgb(60, 72, 88);
        font-weight: 300;
        padding-right: 16px; 
        box-sizing: border-box;
        text-overflow: ellipsis;

        /* Required for text-overflow to do anything */
        white-space: nowrap;
        overflow: hidden;
    }
    &.active{
        color: #3c4858;
        opacity: 1;
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(60,72,88,.4);
        &:hover{
            background-color: rgba(255,255,255,1);
            color: #3c4858;
            box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(60,72,88,.4);
        }
    }
    &:hover{
        background-color: rgba(200,200,200,.2);
        color: #3c4858;
        box-shadow: none;
    }

    // &.open{
        // .dropdown-carret{
        //     transform: translateY(-50%) rotate(180deg);
        // }
    // }
    
}
    

.submenu{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .MuiCollapse-container{
        width: 100%;
    }
    .menu-item{
        &:first-child{
            margin-top: 15px;
        }
        p{
            line-height: 21px;
            font-size: 13px;
        }
    }
}