@import '../../constants';

.MuiButton-contained{
    padding: 12px 30px !important;
    &.small{
        padding: 6.5px 20px !important;
        span{
            font-size: 11px !important;
            line-height: 16.5px !important;
            font-weight: 400 !important;
        }
    }
    span{
        font-size: 12px !important;
        line-height: 17px !important;
        font-weight: 400 !important;
    }
}

.MuiButton-contained.primary-btn-color{
    background-color: $primaryColor;
    color: #fff;
    border-color: #2196f3;
    box-shadow: 0 2px 2px 0 rgba(33,150,243,.14), 0 3px 1px -2px rgba(33,150,243,.2), 0 1px 5px 0 rgba(33,150,243,.12);
    &:hover{
        box-shadow: 0 14px 26px -12px rgba(33,150,243,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(33,150,243,.2);
        color: #fff;
        background-color: #128ff2;
        border-color: #0b76cc;
    }
}

.MuiButton-contained.grey-btn-color{
    background-color: #999;
    color: #fff;
    border-color: #999;
    box-shadow: 0 2px 2px 0 rgba(153,153,153,.14), 0 3px 1px -2px rgba(153,153,153,.2), 0 1px 5px 0 rgba(153,153,153,.12);
    &:hover{
        box-shadow: 0 14px 26px -12px rgba(153,153,153,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(153,153,153,.2);
        color: #fff;
        background-color: #919191;
        border-color: #7a7a7a;
    }
}

.MuiButton-contained.red-btn-color{
    background-color: #f44336;
    color: #fff;
    border-color: #f44336;
    box-shadow: 0 2px 2px 0 rgba(244,67,54,.14), 0 3px 1px -2px rgba(244,67,54,.2), 0 1px 5px 0 rgba(244,67,54,.12);
    &:hover{
        box-shadow: 0 14px 26px -12px rgba(244,67,54,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(244,67,54,.2);
        color: #fff;
        background-color: #f33527;
        border-color: #e11b0c;
    }
}

.MuiButton-contained.green-btn-color{
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50;
    box-shadow: 0 2px 2px 0 rgba(76,175,80,.14),0 3px 1px -2px rgba(76,175,80,.2),0 1px 5px 0 rgba(76,175,80,.12);
    &:hover{
        color: #fff;
        background-color: #47a44b;
        border-color: #39843c;
        box-shadow: 0 2px 2px 0 rgba(76,175,80,.14),0 3px 1px -2px rgba(76,175,80,.2),0 1px 5px 0 rgba(76,175,80,.12);
    }
}