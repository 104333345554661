.error-message{
    background-color: rgb(245, 81, 69);
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #fff;
    border-radius: 3px;
    box-shadow:rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgba(244, 67, 54, 0.4) 0px 7px 10px -5px;
    padding: 20px 15px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 16px;
}