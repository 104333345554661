.price-act-result-block{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.act-price-nds-block{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    p{
        font-size: 17px;
        font-weight: 700;
        color:rgb(60, 72, 88);
        padding: 20px;
        line-height: 21px;
        min-width: 160px;
        text-align: center;
    }
}

.act-price-result-block{
    border-top: 2px solid rgb(128, 128, 128);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    p{
        font-size: 24px;
        font-weight: 700;
        color:rgb(60, 72, 88);
        padding: 20px;
        line-height: 21px;
        min-width: 160px;
        text-align: center;
    }
}

.act-btn-block{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}