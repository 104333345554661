.card-with-header{
    background: #fff;
    padding:  0 20px 20px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 4px 0px;
    border-radius: 6px;
}

.card-header{
    box-sizing: border-box;
    // margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: -20px;
    margin-left: -5px;
    margin-right: -5px;
    p{
        margin: 0;
        font-size: 18px;
        font-weight: 300;
        line-height: 25px;
        color: rgb(60, 72, 88);
        
    }
}

.card-header-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-right: 15px;
    height: 64px;
    width: 64px;
    background: linear-gradient(60deg, rgb(33, 150, 243), rgb(57, 161, 244));
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgba(33, 150, 243, 0.4) 0px 7px 10px -5px;
    .material-icons{
        font-size: 24px;
        color: #fff;
    }
}

.card-body{
    padding-top: 18px;
}