.pmu-request-list-title {
    position: relative;
    padding: 0 0 0 70px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}

.pmu-request-list-title .title-icon{
    position: absolute;
    left: 0;
    top: 50%;
    margin: -25px 0 0;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    text-align: center;
    background-color: #d7d7d7;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

.pmu-request-list-title .title-icon > .material-icons {
    line-height: 50px;
    color: #636363;
    height: 100%;
}

.pmu-request-list-title .title-body {
    box-sizing: border-box;
    color: rgb(60, 72, 88);
    display: block;
    font-size: 20px;
    font-weight: 700;
    height: 20px;
    line-height: 20px;
    margin-bottom: 15px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
    text-align: center;
    text-decoration-color: rgb(60, 72, 88);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-size-adjust: 100%;
    transition-duration: 0.3s;
    transition-property: none;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-template{
    flex: 1;
    width: 100%;
    padding-bottom: 30px;
    min-width: 1350px;
    // justify-content: flex-start;
}

.grey-btn{
    border-radius: 40px;
    box-shadow: 0 0 0 1px #747474;
    height: 40px;
    width: 40px;
    padding: 0 !important;
    margin: 4px !important;
    .material-icons{
        font-size: 20px !important;
    }
}

.grey-btn-full{
    color: #fff !important;
    border-radius: 40px;
    background: #999 !important;
    box-shadow: 0 0 0 1px #999;
    height: 40px;
    width: 40px;
    padding: 0 !important;
    margin: 4px !important;
    .material-icons{
        font-size: 20px !important;
    }
}

.orange-btn-full{
    color: #fff !important;
    border-radius: 40px;
    background: rgb(255, 152, 0) !important; ///#999 !important;
    border-color: #ff9800;
    box-shadow: 0 2px 2px 0 rgba(255,152,0,.14), 0 3px 1px -2px rgba(255,152,0,.2), 0 1px 5px 0 rgba(255,152,0,.12);
    height: 40px;
    width: 40px;
    
    padding: 0 !important;
    margin: 4px !important;
    .material-icons{
        font-size: 20px !important;
    }
}


.upside-down {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.content-right{
    display: flex;
    justify-content: flex-end;
    align-items: 'center';
}

.filter-block{
    margin-top: 24px;
    background: #fff;
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    padding: 24px 30px;
    .field-wrapper {
        flex: 1;
        margin: 0 15px;
        max-width: 300px;
        padding-bottom: 0;
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
    }
}



.add-button{
    background-color: #2196f3;//rgb(153, 153, 153);
    box-shadow:rgba(0, 0, 0, 0.12) 0px 1px 3px 0px, rgba(0, 0, 0, 0.24) 0px 1px 2px 0px;
    border-radius: 50%;
    border: none;
    height: 56px;
    width: 56px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 9999;
    transition: .3s;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
    }
    .material-icons{
        color: #fff;
        font-size: 24px;
    }
}

.tab-wrapper{
    // padding: 24px 30px;
    margin: 24px 30px;
    // border: 1px solid #dee2e6;
    box-shadow: 0 0 0 1px #d7d7d7;
    border-radius: 3px;
    background: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    .tab-point{
        flex: 1;
        padding: 10px 15px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 12px;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;
        border-right: 1px solid #d7d7d7;
        transition: .3s;
        cursor: pointer;
        &:last-child{
            border-right: none;
        }
        &.active{
            background: #d7d7d7;
        }
    }
    // padding: 0 15px;
}