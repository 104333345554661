.section-block{

    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    border-radius: 5px;
    margin: 0 30px;
    line-height: 30px;
    margin-bottom: 24px;
    .section-header{
        width: 100%;
        font-weight: 700;
        font-size: 18px;
        border-bottom: 1px solid #000000;
        color: rgb(60, 72, 88);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p{
            padding: 20px;
            box-sizing: border-box;
            flex: 1;
            border-left: 1px solid #000;
            &:first-child{
                border: none
            }
        }
        &.center-title{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
    .section-body{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        align-items: stretch;
    }
    .half-block{
        &:first-child{
            border: none;
            border-right: 1px solid #000;
            margin-right: -1px;
        }
        // min-height: 100%;
        flex: 1 0 ;
        // margin-left: -1px;
        border-left: 1px solid #000;
        // box-sizing: border-box;
    }
}
