.array-section{
    &:first-of-type{
        border: none;
    }
    width: 100%;
    border-top: 2px solid rgb(215, 215, 215);
}

.i-point-checkbox{
    
    padding: 5px 20px;
    // height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    p{
        font-size: 14px;
        line-height: 40px;
        color: rgb(60, 72, 88);
    }
    .point-checkbox{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 24px;
        cursor: pointer;
        span{
            position: relative;
            height: 16px;
            width: 16px;
            border: 2px solid rgba(0, 0, 0, 0.54);
            border-radius: 50%;
            transition: .3s;
            &:before{
                content: "";
                height: 10px;
                width: 10px;
                transition: .3s;
                background: transparent;
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                border-radius: 50%;
            }
        }
        p{
            padding-left: 8px;
            font-size: 14px;
            line-height: 20px;
            color: rgb(170, 170, 170);

        }
        &.active{
            span{

                border: 2px solid rgb(33, 150, 243);
                &:before{
      
                    background: #2196f3;

                }
            }
        }
    }
}

.add-block-line{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-top: 2px solid rgb(215, 215, 215);
    box-sizing: border-box;
    div{
        margin: 5px 0;
        height: 41px;
        width: 41px;
        border-radius: 50%;
        background-color:rgb(153, 153, 153);
        
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .3s;
        box-shadow: 0 2px 2px 0 rgba(153,153,153,.14), 0 3px 1px -2px rgba(153,153,153,.2), 0 1px 5px 0 rgba(153,153,153,.12);
        &:hover{
            box-shadow: 0 14px 26px -12px rgba(153,153,153,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(153,153,153,.2);
        }

        .material-icons{
            font-size: 20px;
            color: #fff;
        }
    }
    p{
        color: #000;
        font-size: 14px;
        font-weight: 700;
    }
}

.fields-section{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -1px;
    position: relative;
    // z-index: 100;
    // &:first-child{
    //     z-index: 200;
    // }
    .MuiButtonBase-root{
        margin-top: 35px !important;
    }
    &.top-sparator{
        border-top: 1px solid #b8b8b8;
        &:first-child{
            border-top: none;
        }
    }
    &.padding{
        padding: 15px 0;
        box-sizing: border-box;
    }
    &.line{
        &:before{
            content: "";
            display: block;
            position: absolute;
            top: 35px;
            left: 0;
            height: 0px;
            width: 100%;
            border-top: 1px solid #000;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0;
            height: 0px;
            width: 100%;
            border-top: 1px solid #000;
        }

        &:first-of-type{
            .delete-row-btn{
                top: 68%;
            }
        }
    }
    label + .MuiInput-formControl {
        margin-top: 0px;
    }

    .MuiInput-formControl {
        margin-top: 16px !important;
    }


}

.delete-row-btn{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0px 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color:rgb(153, 153, 153);;
    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    box-shadow: 0 2px 2px 0 rgba(153,153,153,.14), 0 3px 1px -2px rgba(153,153,153,.2), 0 1px 5px 0 rgba(153,153,153,.12);
    &:hover{
        box-shadow: 0 14px 26px -12px rgba(153,153,153,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(153,153,153,.2);
    }

    &.disable{
        cursor: no-drop;
    }

    .material-icons{
        font-size: 17px;
        color: #fff;
    }
}

.roads-block{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
    p{
        font-size: 14px;
        line-height: 56px;
        color: rgb(60, 72, 88);
        margin-right: 35px;
    }
    .roads-fields{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        label + .MuiInput-formControl {
            margin-top: 0px;
        }
    
        .MuiInput-formControl {
            margin-top: 16px !important;
        }
    }
    
}

.road-field{
    position: relative;
    margin-right: 40px;
    .delete-row-btn{
        right: 0;
        transform: translate(100%, -50%);
    }
}

.routes-btns-block{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}