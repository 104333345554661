@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");

@import "constants";

$class-slug: spacer !default;

@for $i from 1 through 12 {
  .#{$class-slug}-#{$i} {
    width: 100% / 12 * $i;
  }
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  // padding: 0;
  background: #eee;
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

p,
span,
input,
textarea,
a,
h1,
h2,
h3,
h4,
h5,
h6,
div {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spinner {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 50px 0;
}

.MuiCircularProgress-root {
  height: 50px !important;
  width: 50px !important;
}
.MuiCircularProgress-svg {
  color: #4285f4;
  animation: colorSpinner infinite 4s linear;
  circle {
    stroke-width: 2;
  }
}

@keyframes colorSpinner {
  0% {
    color: #4285f4;
  }
  25% {
    color: #db4437;
  }
  50% {
    color: #f4b400;
  }
  75% {
    color: #0f9d58;
  }
  100% {
    color: #4285f4;
  }
}

.MuiDialog-paper {
  overflow-y: unset !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiDialog-paperWidthSm {
  max-width: 800px !important;
}

.screen-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.screen-top {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100%;//100vw;
}

.info-block {
  padding: 20px 15px;
  border-radius: 3px;
  background: $primaryColor;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(33, 150, 243, 0.4);
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.add-btn-block {
  // background-color: #999999;
  // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px 0px, rgba(0, 0, 0, 0.24) 0px 1px 2px 0px;

  // &:before{
  // position: absolute;
  border-radius: 28px;
  //   border: none;
  height: 56px;
  width: 56px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  //   cursor: pointer;
  z-index: 9999;
  overflow: hidden;
  transition: 0.3s;
  //   outline: none;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   transition: .3s;

  // }

  .btn-block-item {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    background-color: rgb(153, 153, 153);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 28px;
    transform: translate(-50%, -50%);
    font-size: 14px;
    transition: 0.3s;
    &:nth-child(2) {
      top: 78px;
    }
    &:nth-child(3) {
      top: 128px;
    }
  }

  &:hover {
    height: 220px;
  }

  &:hover + .add-btn-bg {
    height: 100%;
    width: 100%;
    z-index: 999;
    opacity: 1;
    // &:before{
    //   height: 100% !important;
    //   width: 100% !important;
    // background: rgba(255, 255, 255, .3);
    //   height: 56px;
    //   width: 56px;
    //   border-radius: 0;
    //   right: 0;
    //   bottom: 0;
    // }
  }
}

.add-btn-bg {
  height: 100%;
  width: 100%;
  z-index: -100;
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  // z-index: 999;
  // height: 0;
  // width: 0;
  transition: 0.3s;
}

.blue-btn {
  &.fullwidth {
    width: 100%;
  }
  padding: 12px 30px !important;
  background-color: rgb(33, 150, 243) !important;
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
  border: 0;
  margin: 0;
  display: block;
  padding: 5px 0;
  font-size: 14px;
  min-width: 100%;
  box-shadow: none;
  box-sizing: border-box;
  list-style: none;
  text-align: left;
  border-radius: 4px;
  background-clip: padding-box;
  background-color: #fff;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  clear: both;
  color: #333;
  margin: 0 5px;
  display: block;
  padding: 0px 6px;
  // font-size: 13px;
  transition: all 150ms linear;
  // font-weight: 400;
  // line-height: 2;
  white-space: nowrap;
  border-radius: 2px;
  padding-right: 30px;
  max-height: 36px;
  height: 36px;
  min-height: 36px;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 36px;
  &:hover {
    background: rgb(33, 150, 243);
    color: #fff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(33, 150, 243, 0.4);
  }
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected {
  background: rgb(33, 150, 243);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(33, 150, 243, 0.4);
  color: #fff;
}

.right-align-margin-reset-password-btn {
  align-self: flex-end !important;
  justify-self: flex-end !important;
  margin-left: 100% !important;
  position: relative;
  transform: translateX(-100%);
  margin-top: 15px !important;
}

$brand-primary: darken(#428bca, 6.5%) !default;
$grey-A200: #eeeeee !default;
$grey-A400: #bdbdbd !default;
////@
/*!
* https://github.com/YouCanBookMe/react-datetime
*/
@mixin shadow-big-color($color) {
  // new box shadow optimized for Tablets and Phones
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba($color, 0.4);
}
.rdt {
  position: relative;
  .rdtPicker {
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0;
  }
  &.rdtOpen {
    .rdtPicker {
      opacity: 1;
      visibility: visible;
      margin-top: 0;
    }
  }
  input.form-control {
    margin-top: 16px;

    border: 0;
    background-image: linear-gradient(#2196f3, #2196f3),
      linear-gradient(rgb(51, 51, 51), rgb(51, 51, 51));
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    // width: 100%;
    // height: 36px;
    padding: 6.125px 0;
    font-size: 14px;
    // line-height: 1.428571429;
    display: block;
    width: calc(100% - 30px);
    color: rgb(51, 51, 51);
    line-height: 15.75px;
    position: relative;

    padding-right: 30px;
  }
  input.form-control:focus {
    outline: none;
    background-image: linear-gradient(#2196f3, #2196f3),
      linear-gradient(rgb(51, 51, 51), rgb(51, 51, 51));
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s;
  }
}
.rdtPicker {
  // display: none;
  position: absolute;
  // width: 290px;
  padding: 4px;
  margin-top: 1px;
  // z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px;
  // z-index: 1000;
  left: 50%;
  transform: translate(-50%, 10px);
  z-index: 3000;
  // bottom: 0;

  // &:before {
  //   display: inline-block;
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   vertical-align: middle;
  //   content: "";
  //   top: -5px;
  //   left: 10px;
  //   right: auto;
  //   color: #ffffff;
  //   border-bottom: 0.4em solid;
  //   border-right: 0.4em solid transparent;
  //   border-left: 0.4em solid transparent;
  // }
  // &:after {
  //   border-bottom: 0.4em solid #ffffff;
  //   border-right: 0.4em solid transparent;
  //   border-left: 0.4em solid transparent;
  //   content: "";
  //   display: inline-block;
  //   position: absolute;
  //   top: -5px;
  //   left: 10px;
  // }
  display: block;
  top: 40px;
  .dow,
  th.rdtSwitch,
  th.rdtNext,
  th.rdtPrev,
  .rdtTimeToggle {
    color: #000; //$brand-primary;
  }
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #fff !important;
  border-collapse: collapse;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtDay {
  height: 40px;
  line-height: 33px;
  width: 40px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 300;
  &.rdtToday.rdtActive,
  &.rdtActive,
  &.rdtActive:hover {
    background-color: $brand-primary !important;
    color: #fff;
    @include shadow-big-color($brand-primary);
  }
}
.rdtDays {
  width: 290px;
  tr {
    .dow {
      border-bottom: 1px solid #e3e3e3;
      text-align: center;
      font-size: 12px;
      // text-transform: uppercase;
      font-weight: inherit;
      padding-bottom: 11px;
      padding-top: 10px;
      color: rgba(0, 0, 0, 0.4);
      text-transform: lowercase;
    }
    .rdtOld,
    .rdtNew {
      color: $grey-A400;
    }
  }
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}
.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px;
  color: #000;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  // line-height: 33px;
  line-height: 40px;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}


.rdtCounters {
  display: inline-block;
  > div {
    float: left;
    width: 40px;
    font-weight: inherit;
    margin: 3px;
    border-radius: 50%;
  }
  .rdtCounterSeparator {
    width: 0;
    border: 1px solid transparent;
  }
}

.rdtCounter {
  height: 100px;
  width: 40px;
  .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent;
    font-size: inherit;
    line-height: 25px;
  }
}

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px;
}
.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: $grey-A200;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px;
}
.rdtMonths,
.rdtYears {
  padding-bottom: 10px;
  .rdtMonth,
  .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;

    &.rdtActive {
      background-color: $brand-primary !important;
      color: #fff;
    }
  }
}
