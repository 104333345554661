.dashboard-screen{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .dashboard{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // align-items: center;
        align-items: flex-start;
        height: 100vh;
        overflow-y: scroll;
        width: calc(100% - 260px);
        position: relative;
        z-index: 1;
    }
    .dashboard-header{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 30px;
        box-sizing: border-box;
        margin-bottom: 30px;
        z-index: 200;
    }
    .back-btn{
        background: #fff;
        height: 40px;
        width: 40px;
        // font-size: 20px !important; 
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid #999;
        padding: 0;
        transition: .3s;
        // color: rgb(153, 153, 153) !important;
        .material-icons{
            font-size: 20px;
            color: rgb(153, 153, 153);
        }
        &:hover{
            box-shadow: 0 14px 26px -12px rgba(153,153,153,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(153,153,153,.2) !important;
        }
    }

    .notification-btn{
        height: 50px;
        width: 50px;
        padding: 15px;
        position: relative;
        box-sizing: border-box;
        cursor: pointer;
        .material-icons{
            font-size: 20px;
            color: rgb(85, 85, 85) !important;
        }
        .count{
            position: absolute;
            top: 5px;
            border: 1px solid #fff;
            right: 7px;
            font-size: 9px;
            background: #f44336;
            color: #fff;
            min-width: 20px;
            padding: 0 5px;
            height: 20px;
            border-radius: 10px;
            text-align: center;
            line-height: 19px;
            vertical-align: middle;
            display: block;
            font-weight: 400;
            box-sizing: border-box;
        }
    }
}

.notification-list-wrapper{
    // position: absolute;
    // right: 30px;
    // bottom: -154px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 340px;
    z-index: 2000;
    .see-all{
        padding: 10px 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        border-top: 1px solid rgb(204, 204, 204);
        a{
            color: rgb(33, 150, 243);
            text-decoration: none;
        }
    }
    .notification-compant{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 5px;
        box-sizing: border-box;
       
        .notification-compant-item{
            box-sizing: border-box;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 30px 10px 24px;
            background: #fff;
            transition: .3s;
            border-radius: 2px;
            position: relative;
            &:hover{
                background: rgb(33, 150, 243);
                button{
                    .material-icons{
                        font-size: 20px;
                        color: #fff;
                    }
                }
            }
            &>.material-icons{
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #004a93;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid #fff;
                font-size: 14px;
                color: #fff;
                margin-right: 6px;
            }
            p{
                font-size: 13px;
                font-weight: 400px;
                line-height: 18px;
                color: rgb(33, 37, 41);
            }
            button{
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                background: none;
                border: none;
                outline: none;
                height: 20px;
                width: 20px;
                transition: .3s;
                padding: 0;
                border-radius: 2px;
                cursor: pointer;
                &:hover{
                    background: rgba(0, 0, 0, .1);

                }
                .material-icons{
                    font-size: 20px;
                    color: rgb(33, 37, 41);
                }
            }
        }
    }
}

.form-dialog-window{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .form-dialog-header{
        padding: 24px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        p{
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            // width: 100%;
        }
        button{
            background: transparent;
            border: none;
            position: absolute;
            right: 10px;
            top: 10px;
            padding: 0;
            color: #666;
            outline: none;
            cursor: pointer;
            transition: .3s;
            &:hover{
                color: #999;
            }
        }
    }
} 

.dialog-window {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    h4{
        margin: 0;
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin-top: 4px;
        text-transform: uppercase;
    }
    p{
        display: block;
        position: relative;
        max-width: 100%;
        margin: 0 0 12px;
        padding: 0;
        color: #595959;
        line-height:45px;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        text-transform: none;
        word-wrap: break-word;
        max-width: 472px;
        &.small-text{
            font-size: 18px;
            font-weight: 300;
            line-height: 25px;
            max-width: 95%;
        }
    }
    .btn-block{
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}