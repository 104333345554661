.error-dialog-window{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    p{
        font-size: 18px;
        font-weight: 300;
        line-height: 21px;
        color: rgb(84, 84, 84);
    }
    button{
        margin: 20px 192px 0 !important;
    }
}

.swal2-x-mark{
    border: 4px solid rgb(242, 116, 116);
    height: 80px;
    width: 80px;
    position: relative;
    border-radius: 44px;
    margin: 20px 192px 40px;
    .swal2-x-mark-line-left{
        position: absolute;
        width: 47px;
        height: 5px;
        background: rgb(242, 116, 116);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        border-radius: 2px;
    }
    .swal2-x-mark-line-right{
        position: absolute;
        width: 47px;
        height: 5px;
        background: rgb(242, 116, 116);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        border-radius: 2px;
    }
}