.title-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 35%;
    margin-top: -32px;
    margin-bottom: 24px;
    margin-left: 101px;
    p{
        color: rgb(60, 72, 88);
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
    }
}

.array-section{
    &:first-of-type{
        border: none;
    }
    border-top: 2px solid rgb(215, 215, 215);
}

.i-point-checkbox{

    padding: 5px 20px;
    // height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    p{
        font-size: 14px;
        line-height: 40px;
        color: rgb(60, 72, 88);
    }
    .point-checkbox{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 24px;
        cursor: pointer;
        span{
            position: relative;
            height: 16px;
            width: 16px;
            border: 2px solid rgba(0, 0, 0, 0.54);
            border-radius: 50%;
            transition: .3s;
            &:before{
                content: "";
                height: 10px;
                width: 10px;
                transition: .3s;
                background: transparent;
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                border-radius: 50%;
            }
        }
        p{
            padding-left: 8px;
            font-size: 14px;
            line-height: 20px;
            color: rgb(170, 170, 170);

        }
        &.active{
            span{

                border: 2px solid rgb(33, 150, 243);
                &:before{

                    background: #2196f3;

                }
            }
        }
    }
}

.add-block-line{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-top: 2px solid rgb(215, 215, 215);
    box-sizing: border-box;
    div{
        margin: 5px 0;
        height: 41px;
        width: 41px;
        border-radius: 50%;
        background-color:rgb(153, 153, 153);

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .3s;
        box-shadow: 0 2px 2px 0 rgba(153,153,153,.14), 0 3px 1px -2px rgba(153,153,153,.2), 0 1px 5px 0 rgba(153,153,153,.12);
        &:hover{
            box-shadow: 0 14px 26px -12px rgba(153,153,153,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(153,153,153,.2);
        }

        .material-icons{
            font-size: 20px;
            color: #fff;
        }
    }
    p{
        color: #000;
        font-size: 14px;
        font-weight: 700;
    }
}

.fields-section{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -1px;
    position: relative;
    width: 100%;
    &.line{
        &:before{
            content: "";
            display: block;
            position: absolute;
            top: 35px;
            left: 0;
            height: 0px;
            width: 100%;
            border-top: 1px solid #000;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0;
            height: 0px;
            width: 100%;
            border-top: 1px solid #000;
        }

        &:first-of-type{
            .delete-row-btn{
                top: 68%;
            }
        }
    }
    label + .MuiInput-formControl {
        margin-top: 0px;
    }

    .MuiInput-formControl {
        margin-top: 16px !important;
    }


}

.delete-row-btn{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0px 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color:rgb(153, 153, 153);;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    box-shadow: 0 2px 2px 0 rgba(153,153,153,.14), 0 3px 1px -2px rgba(153,153,153,.2), 0 1px 5px 0 rgba(153,153,153,.12);
    &:hover{
        box-shadow: 0 14px 26px -12px rgba(153,153,153,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(153,153,153,.2);
    }

    &.disable{
        cursor: no-drop;
    }

    .material-icons{
        font-size: 17px;
        color: #fff;
    }
}

.roads-block{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 20px;
    box-sizing: border-box;
    p{
        font-size: 14px;
        line-height: 56px;
        color: rgb(60, 72, 88);
        margin-right: 35px;
    }
    .roads-fields{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        label + .MuiInput-formControl {
            margin-top: 0px;
        }

        .MuiInput-formControl {
            margin-top: 16px !important;
        }
    }

}

.road-field{
    position: relative;
    margin-right: 40px;
    .delete-row-btn{
        right: 0;
        transform: translate(100%, -50%);
    }
}

.routes-btns-block{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.steps-tab{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    box-sizing: border-box;
    margin-bottom: 24px;
    .steps-tab-item{
        text-align: center;
        text-transform: uppercase;
        padding: 5px;
        background-color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border: 1px solid #d7d7d7;
        border-left: none;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        color: rgb(60, 72, 88);
        width: 33.3%;
        &.active{
            background-color: #d7d7d7;
        }
        &:first-child{
            border-left: 1px solid #d7d7d7;
            border-radius: 5px 0 0 5px;
        }
        &:last-child{
            border-radius: 0 5px 5px 0;
        }
    }
}
.routes-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    width: 50%;
    p{
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
        color: #000;
        // padding: 20px;
        margin-right: 5px;
        
    }
}

.no-data-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    color: rgb(60, 72, 88);
    padding: 24px;
    box-sizing: border-box;
}

.order-price-block{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    &>div{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.result{
            padding: 52px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            // border-right: 1px solid
        }
        &.price{
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: rgb(215, 215, 215) -1px 0px 0px 0px;
            height: 100%;
            padding: 15px 0;
            .field-wrapper.center-field-mode{
                padding: 0px !important;
                input{
                    font-size: 18px;
                }
            }
            &>p{
                margin-bottom: 27px;
            }
        }
        .result p, .price p{
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
        }
    }
}

.wright-comment-block{

    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-left: 270px;
    box-sizing: border-box;
    padding-bottom: 22.5px;
    border-bottom: 1px solid #000;
    width: 100%;
    margin-bottom: 10px;
    .material-icons{
        background-color: #d3d3d3;
        box-shadow: 0 0 0 1px rgba(0,0,0,.6);
        border-radius: 100%;
        box-sizing: border-box;
        padding: 6px;
        height: 36px;
        width: 36px;
        position: relative;
        z-index: 1;
        color: rgb(60, 72, 88);
        margin: 0 50px;
        position: relative;
        overflow: unset;
    }
    .field-wrapper{
        width: 645px;
        margin-bottom: 0px;
    }
    .btn-wright-wrapper{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}

.event-list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 8px 15px;
    box-sizing: border-box;
    
    &.without-lines{
        .status{
            .material-icons{
                &:before{
                    height: 0;
                }
            }
        }
    }
    .time{
        width: 255px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        p{
            font-size: 12px;
            line-height: 15px;
            color: #000;
            font-weight: 300;
        }

    }
    &:first-child{
        padding-top: 20px;
    }
    &:last-child{
        padding-bottom: 20px;
        .status{
            .material-icons{
                &:before{
                    height: 0;
                }
            }
        }
    }
    .status{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .material-icons{
            background-color: #d3d3d3;
            box-shadow: 0 0 0 1px rgba(0,0,0,.6);
            border-radius: 100%;
            box-sizing: border-box;
            padding: 6px;
            height: 36px;
            width: 36px;
            position: relative;
            z-index: 1;
            color: rgb(60, 72, 88);
            margin: 0 50px;
            position: relative;
            overflow: unset;
            &:before{
                content: "";
                display: block;
                position: absolute;
                height: 19px;
                width: 2px;
                background: #d3d3d3;;
                bottom: -39px;
                left: 50%;
                transform: translate(-50%, -100%);
            }
        }
        p{
            background-color: #eee;
            border-radius: 3px;
            padding: 10px 20px;
            color: #000;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
            width: 645px;
            word-break: break-all;
            
        }
    }
    &>p{
        font-size: 12px;
        line-height: 15px;
        color: #000;
        font-weight: 300;
        width: 330px;
        text-align: center;
        flex: 1;
    }
}
.msg{
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    padding: 24px;
    box-sizing: border-box;
    font-weight: 300;
}


.half-block{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.command-panel-btn{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 13px 0px;
    box-sizing: border-box;
}

.route-list-btn-wrapper{
    margin-top: 17.5px;
    width: 50%;
}

.status-checker{
    padding: 10px 20px;
    color:rgb(60, 72, 88);
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
    border-left: 5px solid rgb(103, 58, 183);
    margin-left: 20px;
    border-radius: 5px;
    background: #fff;
    max-width: 200px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    &.FORMED{
        border-color: rgb(76, 175, 80);
    }
    &.UNDER_CONSIDERATION{
        border-color: rgb(249, 168, 37);
    }
    &.REJECTED{
        border-color: rgb(244, 67, 54);
    }
    &.AGREED{
        border-color: rgb(103, 58, 183);
    }
    &.ON_ROUTE{
        border-color: rgb(109, 76, 65);
    }
    &.COMPLETED{
        border-color: rgb(0, 119, 255);
    }
    &.ARCHIEVED{
        border-color: rgb(96, 125, 139);
    }
    &.CHANGED{
        border-color: rgb(96, 125, 139);
    }



}