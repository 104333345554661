.form-block{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(215, 215, 215);
    width: 100%;
    &.compact-padding{
        padding: 13px 10px 5px;
        .field-wrapper{
            margin-bottom: 8px;
        }
    }
    &.compact-style{
        .form-block-title-part{
            p{
                font-size: 12px;
            }
        }
    }
    &.without-border{
        border-bottom: none;
    }
    .form-block-title-part{
        width: 33.3333%;
        margin-top: 13px;
        p{
            width: 100%;
            padding-right: 15px;
            box-sizing: border-box;
            color:rgb(60, 72, 88);
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
        }
    }
    .form-block-content-part{
        &.content-center{
            justify-content: center;
        }
        width: calc(66.6667% + 30px);
        // padding-left: 15px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: -15px;
        margin-left: -15px; 
    }
}