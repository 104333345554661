.no-data-block{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    p{
        color: rgb(60, 72, 88);
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
        margin-top: 20px;
    }
}