.simple-page-form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 24px;
}

.simple-page-card-form{
    margin: 0 15px;
    border-radius: 5px;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    margin-bottom: 70px;
}

.simple-page-card-header{
    padding: 20px;
    box-sizing: border-box;
    color: rgb(60, 72, 88);
    border-bottom: 2px solid rgb(215, 215, 215);
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
}